import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import flowchartImg from "assets/images/products/SOM/HomePage/XENO+Flowchart.jpg";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import gsap from 'gsap';
import { useEffect, useRef } from 'react';
function FlowChart() {

    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <>
            <MKBox component="section" mt={5} py={2} id='flowchart'>
                <Container>
                    <Grid container item xs={12} md={12} lg={12}>
                        {/* <Grid item xs={12} md={4} lg={12} justifyContent="center" justifyItems="center" alignItems="center">
                            <MKTypography
                                variant="h4"
                                color="bgdark"
                                textTransform="capitalize"
                                fontWeight="bold" justifyContent="center" justifyItems="center" alignItems="center"
                            >
                                How Our Xeno+ works
                            </MKTypography>
                        </Grid> */}

                        <Grid container item xs={12} lg={12} mx={1} mb={2} justifyContent="center">
                            <MKTypography
                                variant="h4"
                                color="dark"
                                textAlign="center"
                                textTransform="capitalize"
                                ref={textRef}
                            >
                                How XENO+ ML SOM Works
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} justifyContent="center" >
                            <MKBox component="img" src={flowchartImg} alt="Wifi IMAGE" height="auto" width="100%" borderRadius="xl" />
                            {/* <MKTypography
                                variant="body1"
                                color="bgbgdark"
                                textAlign="justify"
                                fontWeight="regular"
                            >
                                Eoxys is one among top AIML/IoT product engineering and cloud SW engineering companies
                                in India offering AIML based SOM modules, AIML EVK Kits and Cloud SW design & development. Our
                                latest XENO+ series ML SOM modules help our customers to build
                                their Battery powered AIML/IoT devices in short time.
                                By working closely with our B2B clients and utilizing our
                                proprietary XENO+ ML SOM modules, Eoxys has successfully helped customers to build Wear
                                Monitoring Device, Audio Classification Device, IoT Camera,
                                BLE Beacon Gateway, Solar powered Weather Stations, Air
                                quality sensor and several other AIML/IoT Devices with
                                BLE/Wi-Fi/LTE/NBIOT/GPS interfaces.
                            </MKTypography> */}
                        </Grid>
                    </Grid>
                </Container>
            </MKBox></>
    );
}

export default FlowChart;
