// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SwipeableTextMobileStepper from "components/Carousel";
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

// Images
import testimonial_img from "assets/images/icons/testimonial_icon1.png"
function Client() {

    const images = [
        {
            label: "We appreciate Eoxys technical team’s effort for developing  team worked and delivered well what they have committed at the initiation of the project,            like project technical approach, milestone deliverables, quality aspects and complete project deliverables, also their support during the field test of the devices",
            imgPath: testimonial_img,
            footer: "By Major Indian Industrial Pipeline manufacturer"
        },
        {
            label: "We appreciate Eoxys XENO+ WiFi+BLE nano module, an elegant device with technology compactness. Our college students used XENO+ nano module for their real time IoT application projects with no time. Good work Eoxys Technical XENO+ team for this outstanding product",
            imgPath: testimonial_img,
            footer: "By Reputed Engineering University"
        },
        {
            label: "Eoxys’ s TuneApp server is user-friendly tool as a plug and play type of software to build the executable process blocks. Our Support team is happy to integrate with our banking customer for timely alerts from deployed sensor events across the connected systems",
            imgPath: testimonial_img,
            footer: "By Major Japanese MNC Company"
        },
        {
            label: "Great time with Eoxys team for developing our AIML – Audio and Image classification device for pest sensing and classification requirements. Their technical excellency is good to explore the new possible solutions to meet the timeline for go to market",
            imgPath: testimonial_img,
            footer: "By Major German MNC company"
        },
        {
            label: "Awesome Eoxys team for timely delivery of ECU unit and the device is working as expected. We are satisfied with your delivery and support tasks",
            imgPath: testimonial_img,
            footer: "By Major Indian Automotive OEM"
        },
    ];
    const images1 = [
        {
            label: "We appreciate Eoxys XENO+ WiFi+BLE nano module, an elegant device with technology compactness. Our college students used XENO+ nano module for their real time IoT application projects with no time. Good work Eoxys Technical XENO+ team for this outstanding product",
            imgPath: testimonial_img,
            footer: "By Reputed Engineering University"
        },
        {
            label: "Eoxys’ s TuneApp server is user-friendly tool as a plug and play type of software to build the executable process blocks. Our Support team is happy to integrate with our banking customer for timely alerts from deployed sensor events across the connected systems",
            imgPath: testimonial_img,
            footer: "By Major Japanese MNC Company"
        },
        {
            label: "Great time with Eoxys team for developing our AIML – Audio and Image classification device for pest sensing and classification requirements. Their technical excellency is good to explore the new possible solutions to meet the timeline for go to market",
            imgPath: testimonial_img,
            footer: "By Major German MNC company"
        },
        {
            label: "Awesome Eoxys team for timely delivery of ECU unit and the device is working as expected. We are satisfied with your delivery and support tasks",
            imgPath: testimonial_img,
            footer: "By Major Indian Automotive OEM"
        },
        {
            label: "We appreciate Eoxys technical team’s effort for developing  team worked and delivered well what they have committed at the initiation of the project,            like project technical approach, milestone deliverables, quality aspects and complete project deliverables, also their support during the field test of the devices",
            imgPath: testimonial_img,
            footer: "By Major Indian Industrial Pipeline manufacturer"
        },
    ];
    const images2 = [
        {
            label: "Eoxys’ s TuneApp server is user-friendly tool as a plug and play type of software to build the executable process blocks.Our Support team is happy to integrate with our banking customer for timely alerts from deployed sensor events across the connected systems",
            imgPath: testimonial_img,
            footer: "By Major Japanese MNC Company"
        },
        {
            label: "Great time with Eoxys team for developing our AIML – Audio and Image classification device for pest sensing and classification requirements.Their technical excellency is good to explore the new possible solutions to meet the timeline for go to market",
            imgPath: testimonial_img,
            footer: "By Major German MNC company"
        },
        {
            label: "Awesome Eoxys team for timely delivery of ECU unit and the device is working as expected. We are satisfied with your delivery and support tasks",
            imgPath: testimonial_img,
            footer: "By Major Indian Automotive OEM"
        },
        {
            label: "We appreciate Eoxys technical team’s effort for developing  team worked and delivered well what they have committed at the initiation of the project,            like project technical approach, milestone deliverables, quality aspects and complete project deliverables, also their support during the field test of the devices",
            imgPath: testimonial_img,
            footer: "By Major Indian Industrial Pipeline manufacturer"
        },
        {
            label: "We appreciate Eoxys XENO+ WiFi+BLE nano module, an elegant device with technology compactness. Our college students used XENO+ nano module for their real time IoT application projects with no time. Good work Eoxys Technical XENO+ team for this outstanding product",
            imgPath: testimonial_img,
            footer: "By Reputed Engineering University"
        },
    ];

    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <>
            <MKBox id='testimonial'>
                <MKBox component="section" mt={10} py={2}>
                    <Container>
                        <Grid container item xs={12} md={12} lg={12}>
                            <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                                <MKTypography
                                    variant="Overline"
                                    textTransform="capitalize"
                                    fontWeight="light"
                                // sx={({ breakpoints, typography: { size } }) => ({
                                //     [breakpoints.down("md")]: {
                                //         fontSize: size["xl"],
                                //     },
                                // })}
                                >
                                    Eoxys
                                </MKTypography>
                                <MKTypography
                                    variant="h2"
                                    color="dark"
                                    textTransform="capitalize"
                                    fontWeight="bold"
                                    ref={textRef}
                                >
                                    Testimonials
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} justifyContent="center" >
                                <MKTypography
                                    variant="body1"
                                    color="dark"
                                    textAlign="justify"
                                    fontWeight="regular"
                                >
                                    See What our Customer have To say
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container  >
                        <SwipeableTextMobileStepper arraydata1={images} arraydata2={images1} arraydata3={images2} testimonial="true" />
                    </Container>
                </MKBox>
            </MKBox >
        </>

    );
}

export default Client;
