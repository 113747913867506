// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Input from "components/Input";
import { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import gsap from 'gsap';
// Material Kit 2 React examples
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// import Col12Card from "examples/Cards/WebsitesCards/Col12Card/website_col-12_Card"

// Images
// import sommodimg from "assets/images/icons/sommodules.png";
// import hwdevkitimg from "assets/images/icons/hwdevkit.png";
// import rmlmodelsimg from "assets/images/icons/mlmodels.png";
// import swdevsupportimg from "assets/images/icons/swdevsupport_resize.png";

function ContactUS() {

  const [name, setName] = useState("");
  const [isNameTouched, setIsNameTouched] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState(false);

  const [email, setEmail] = useState("");
  const [isEmailTouched, setIsEmailTouched] = useState(false);

  const [companyName, SetCompanyName] = useState("");
  const [isCompanyNameTouched, setIsCompanyNameTouched] = useState(false);

  const [region, setRegion] = useState("");
  const [isRegionTouched, setIsRegionTouched] = useState(false);

  const [message, setMessage] = useState("");
  const [isMessageTouched, setIsMessageTouched] = useState(false);

  const isNameValid = name.trim() !== "";
  const hasNameError = !isNameValid && isNameTouched;

  const isPhoneNumberValid = phoneNumber.trim() !== "";
  const hasPhoneNumberError = !isPhoneNumberValid && isPhoneNumberTouched;

  const isEmailValid =
    email.trim() !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const hasEmailError = !isEmailValid && isEmailTouched;

  const isCompanyNameValid = companyName.trim() !== "";
  const hasCompanyNameError = !isCompanyNameValid && isCompanyNameTouched;

  const isRegionValid = region.trim() !== "";
  const hasRegionError = !isRegionValid && isRegionTouched;

  const isMessageValid = message.trim() !== "";
  const hasMessageError = !isEmailValid && isMessageTouched;


  let isFormValid = false;
  if (isNameValid && isPhoneNumberValid && isEmailValid && isCompanyNameValid && isRegionValid && isMessageValid) {
    isFormValid = true;
  }

  const [api, setApi] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;


  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => emailjs.init("5LXUKd1vwQCU_3X2l"), []);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    console.log("formSubmitHandler");
    // const requestOptions1 = {
    //   method: "POST",
    //   headers: {
    //     'content-type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     "name": name,
    //     "phoneNumber": phoneNumber,
    //     "email": email,
    //     "region": region,
    //     "companyName": companyName,
    //     "message": message,
    //   })
    // };
    // const contactApi = window.contactFormApi;
    // try {
    //   console.log("contactFormApi ", contactApi,requestOptions1)
    //   fetch(contactApi, requestOptions1).then(
    //     (response) => {
    //       console.log(response)
    //       if (response.status == 200) {
    //         setState({ ...state, open: true });
    //         setApi(true);
    //         console.log("Mail sent successfully - statusText", response.statusText);

    //       } else {
    //         setState({ ...state, open: false });
    //         setApi(false);
    //       }
    //     })
    //     .catch((error) => {
    //       setState({ ...state, open: false });
    //       setApi(false);
    //       console.log("error", error);

    //     });
    // } catch (error) {
    //   setState({ ...state, open: false });
    //   setApi(false);
    //   console.log("error", error);
    // }
    const serviceId = "service_hk4fyi4";
    const templateId = "template_jahgaxn";
    try {
      await emailjs.send(serviceId, templateId, {
        "name": name,
        "phoneNumber": phoneNumber,
        "email": email,
        "region": region,
        "companyName": companyName,
        "message": message,
      });
      setState({ ...state, open: true });
      setApi(true);
      // alert("email successfully sent check inbox");
    } catch (error) {
      setState({ ...state, open: false });
      setApi(false);
    }
    setName("");
    setIsNameTouched(false);

    setPhoneNumber("");
    setIsPhoneNumberTouched(false);

    setEmail("");
    setIsEmailTouched(false);

    SetCompanyName("");
    setIsCompanyNameTouched(false);

    setRegion("");
    setIsRegionTouched(false);

    setMessage("");
    setIsMessageTouched(false);
  };

  const NameChangeHandler = (e) => {
    setName(e.target.value);
  };

  const NameBlurHandler = () => {
    setIsNameTouched(true);
  };

  const PhoneNumberChangeHandler = (e) => {
    setPhoneNumber(e.target.value);
  };

  const PhoneNumberBlurHandler = () => {
    setIsPhoneNumberTouched(true);
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const emailBlurHandler = () => {
    setIsEmailTouched(true);
  };
  const companyNameChangeHandler = (e) => {
    SetCompanyName(e.target.value);
  };

  const companyNameBlurHandler = () => {
    setIsCompanyNameTouched(true);
  };
  const regionChangeHandler = (e) => {
    setRegion(e.target.value);
  };

  const regionBlurHandler = () => {
    setIsRegionTouched(true);
  };
  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const messageBlurHandler = () => {
    setIsMessageTouched(true);
  };
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      // Split text into characters for individual animation
      const chars = textRef.current.innerText.split('');
      textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

      // Animate each character with GSAP
      gsap.fromTo(textRef.current.children,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
      );
    }
  }, []);
  return (
    <>
      <MKBox id='contactus'>
        <MKBox component="section" mt={10} py={2}  >
          <Container>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                <MKTypography
                  variant="Overline"
                  color="dark"
                  textTransform="capitalize"
                  fontWeight="light"
                >
                  Eoxys
                </MKTypography>
                <MKTypography
                  variant="h2"
                  color="dark"
                  textTransform="capitalize"
                  fontWeight="bold"
                  ref={textRef}
                >
                  Contact US
                </MKTypography>
              </Grid>
              <Grid item xs={12} md={8} lg={8} justifyContent="justify" >
                <MKTypography
                  variant="body1"
                  color="dark"
                  textAlign="justify"
                  fontWeight="regular"
                >
                  Get in Touch with us Today!
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <MKBox component="section" mt={2} py={2}>
          <Container>
            <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
              <Grid item xs={12} md={12} >

                < MKBox p={2} my={3} textAlign="center" bgColor="cardColorValue" lineHeight={1}
                  borderRadius="xl" shadow="xl" coloredShadow="success"
                  borderColor="success"
                  sx={{
                    border: 0, borderColor: "#4CAF50"
                  }}
                >
                  <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
                    <Grid item xs={12} md={6}>
                      <form onSubmit={formSubmitHandler}>
                        <Grid
                          container
                          item
                          xs={12}
                          lg={12}
                          pt={2}
                          justifyContent="center"
                          mx={1}
                          sx={{ mx: "auto" }}
                        >
                          <Grid item xs={12} md={6} px={2}>
                            <Input
                              labelValue={"NAME *"}
                              id="name"
                              type="text"
                              value={name}
                              onChange={NameChangeHandler}
                              onBlur={NameBlurHandler}
                              errMessage={"Please Enter Your Name"}
                              hasError={hasNameError}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={2}>
                            <Input
                              labelValue={"EMAIL *"}
                              id="email"
                              type="email"
                              value={email}
                              onChange={emailChangeHandler}
                              onBlur={emailBlurHandler}
                              errMessage={"Please Enter Your Email"}
                              hasError={hasEmailError}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={2}>
                            <Input
                              labelValue={"PHONE NUMBER *"}
                              id="phoneNumber"
                              type="tel"
                              value={phoneNumber}
                              onChange={PhoneNumberChangeHandler}
                              onBlur={PhoneNumberBlurHandler}
                              errMessage={"Please Enter Your Phone Number"}
                              hasError={hasPhoneNumberError}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={2}>
                            <Input
                              labelValue={"COMPANY NAME *"}
                              id="companyName"
                              type="text"
                              value={companyName}
                              onChange={companyNameChangeHandler}
                              onBlur={companyNameBlurHandler}
                              errMessage={"Please Enter Your Company Name"}
                              hasError={hasCompanyNameError}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={2}>
                            <Input
                              labelValue={"REGION  *"}
                              id="region"
                              type="text"
                              value={region}
                              onChange={regionChangeHandler}
                              onBlur={regionBlurHandler}
                              errMessage={"Please Enter Your Region"}
                              hasError={hasRegionError}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={2}>
                            <Input
                              labelValue={"MESSAGE *"}
                              id="message"
                              type="text"
                              value={message}
                              onChange={messageChangeHandler}
                              onBlur={messageBlurHandler}
                              multiLine
                              maxRows={5}
                              errMessage={"Please Enter Your Message"}
                              hasError={hasMessageError}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={12}
                          lg={12}
                          pt={2}
                          justifyContent="center"
                          mx={1}
                          sx={{ mx: "auto" }}
                        >
                          <div className="form-actions">


                            {isFormValid && <MKButton
                              variant="outlined"
                              color="success"
                              // component="a"
                              justifyContent="center"
                              textTransform="capitalize"
                              // href=""
                              sx={{ mb: 2 }}
                              type="submit"
                            >
                              <MKTypography
                                variant="body2"
                                textAlign="justify"
                                m="auto"
                                p
                                color="#000"
                                fontWeight="medium"
                              >
                                Submit
                              </MKTypography>
                            </MKButton>}
                            {!isFormValid && <MKButton
                              variant="outlined"
                              color="dark"
                              // component="a"
                              justifyContent="center"
                              textTransform="capitalize"
                              // href=""
                              sx={{ mb: 2 }}
                              type="submit"
                              disabled
                            >
                              <MKTypography
                                variant="body2"
                                textAlign="justify"
                                m="auto"
                                p
                                color="#000"
                                fontWeight="medium"
                              >
                                Submit
                              </MKTypography>
                            </MKButton>}

                          </div></Grid>
                      </form>
                      {api &&
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{ vertical, horizontal }}>
                          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Mail Sent Successfully
                          </Alert>
                        </Snackbar>}
                      {!api && <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                        anchorOrigin={{ vertical, horizontal }}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                          Something Went Wrong , Please try again later
                        </Alert>
                      </Snackbar>}
                    </Grid>
                    <Grid item xs={12} md={6} px={2}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.135723631437!2d77.60493857479457!3d13.027027813661208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1500394c8eb9%3A0x106cea58284495c7!2sEoxys%20Systems%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1691046922610!5m2!1sen!2sin"
                        style={{ border: 0, width: "100%", height: 300 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Grid>
                  </Grid></MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </MKBox>
    </>
  );
}

export default ContactUS;
