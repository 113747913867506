import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import Popper from "@mui/material/Popper";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbarDropdown from "components/Header/DefaultNavbarDropdown";
import DefaultNavbarMobile from "components/Header/DefaultNavbarMobile";
import logoimg from "assets/images/logos/logo7.png";
import breakpoints from "assets/theme/base/breakpoints";
import { layoutGenerator } from 'react-break';

function DefaultNavbar({ routes, transparent, relative, center }) {
  const [dropdown, setDropdown] = useState("");
  const [dropdownEl, setDropdownEl] = useState("");
  const [dropdownName, setDropdownName] = useState("");
  const [nestedDropdown, setNestedDropdown] = useState("");
  const [nestedDropdownEl, setNestedDropdownEl] = useState("");
  const [nestedDropdownName, setNestedDropdownName] = useState("");
  const [arrowRef, setArrowRef] = useState(null);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  const layout = layoutGenerator({
    mobile: 0,
    // phablet: 550,
    // tablet: 768,
    desktop: 992,
  });

  const OnMobile = layout.is('mobile');
  const OnDesktop = layout.is('desktop');

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const renderNavbarItems =
    <>

      {
        routes.map(({ name, icon, href, route, collapse }) => (
          <>
            <DefaultNavbarDropdown
              key={name}
              name={name}
              icon={icon}
              href={href}
              route={route}
              collapse={Boolean(collapse)}
              onMouseEnter={({ currentTarget }) => {
                if (collapse) {
                  setDropdown(currentTarget);
                  setDropdownEl(currentTarget);
                  setDropdownName(name);
                }
              }}
              onMouseLeave={() => collapse && setDropdown(null)}
            />

          </>
        ))}
    </>

// Render the routes on the dropdown menu

  const renderRoutes = routes.map(({ name, collapse, columns, rowsPerColumn }) => {
    let template;

    // Render the dropdown menu that should be display as columns
    if (collapse && columns && name === dropdownName) {
      const calculateColumns = collapse.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / rowsPerColumn);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      template = (
        <Grid key={name} container spacing={3} py={1} px={1.5}>
          {calculateColumns.map((cols, key) => {
            const gridKey = `grid-${key}`;
            const dividerKey = `divider-${key}`;

            return (
              <Grid key={gridKey} item xs={12 / columns} sx={{ position: "relative" }}>
                {cols.map((col, index) => (
                  <Fragment key={col.name}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                      py={1}
                      px={0.5}
                      mt={index !== 0 ? 2 : 0}
                    >
                      {col.name}
                    </MKTypography>
                    {col.collapse.map((item) => (
                      <MKTypography
                        key={item.name}
                        component={item.route ? Link : MuiLink}
                        to={item.route ? item.route : ""}
                        href={item.href ? item.href : (e) => e.preventDefault()}
                        // target={item.href ? "_blank" : ""}
                        rel={item.href ? "noreferrer" : "noreferrer"}
                        minWidth="11.25rem"
                        display="block"
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        fontWeight="regular"
                        py={0.625}
                        px={2}
                        sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                          borderRadius: borderRadius.md,
                          cursor: "pointer",
                          transition: "all 300ms linear",

                          "&:hover": {
                            backgroundColor: grey[200],
                            color: dark.main,
                          },
                        })}
                      >
                        {item.name}
                      </MKTypography>
                    ))}
                  </Fragment>
                ))}
                {key !== 0 && (
                  <Divider
                    key={dividerKey}
                    orientation="vertical"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "-4px",
                      transform: "translateY(-45%)",
                      height: "90%",
                    }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      );

      // Render the dropdown menu that should be display as list items

    } else if (collapse && name === dropdownName) {
      template = collapse.map((item) => {
        const linkComponent = {
          component: MuiLink,
          href: item.href,
          // target: "_blank",
          rel: "noreferrer",
        };

        const routeComponent = {
          component: Link,
          to: item.route,
        };

        return (
          <MKTypography
            key={item.name}
            {...(item.route ? routeComponent : linkComponent)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            variant="button"
            textTransform="capitalize"
            minWidth={item.description ? "14rem" : "12rem"}
            color={item.description ? "dark" : "text"}
            fontWeight={item.description ? "bold" : "regular"}
            py={item.description ? 1 : 0.625}
            px={2}
            sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
              borderRadius: borderRadius.md,
              cursor: "pointer",
              transition: "all 300ms linear",

              "&:hover": {
                backgroundColor: grey[200],
                color: dark.main,

                "& *": {
                  color: dark.main,
                },
              },
            })}
            onMouseEnter={({ currentTarget }) => {
              if (item.dropdown) {
                setNestedDropdown(currentTarget);
                setNestedDropdownEl(currentTarget);
                setNestedDropdownName(item.name);
              }
            }}
            onMouseLeave={() => {
              if (item.dropdown) {
                setNestedDropdown(null);
              }
            }}
          >
            {item.description ? (
              <MKBox>
                {item.name}
                <MKTypography
                  display="block"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  sx={{ transition: "all 300ms linear" }}
                >
                  {item.description}
                </MKTypography>
              </MKBox>
            ) : (
              item.name
            )}
            {item.collapse && (
              <Icon
                fontSize="small"
                sx={{ fontWeight: "normal", verticalAlign: "middle", mr: -0.5 }}
              >
                keyboard_arrow_right
              </Icon>
            )}
          </MKTypography>
        );
      });
    }

    return template;
  });

  // Routes dropdown menu

  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      placement="top-start"
      transition
      style={{ zIndex: 10 }}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null);
          setDropdownName("");
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <MKBox borderRadius="lg">
            <MKTypography variant="h1" color="white">
              <Icon ref={setArrowRef} sx={{ mt: -3 }}>
                arrow_drop_up
              </Icon>
            </MKTypography>
            <MKBox shadow="lg" borderRadius="lg" p={2} mt={2}>
              {renderRoutes}
            </MKBox>
          </MKBox>
        </Grow>
      )}
    </Popper>
  );

  // Render routes that are nested inside the dropdown menu routes

  const renderNestedRoutes = routes.map(({ collapse, columns }) =>
    collapse && !columns
      ? collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
        let template;

        if (parentName === nestedDropdownName) {
          template =
            nestedCollapse &&
            nestedCollapse.map((item) => {
              const linkComponent = {
                component: MuiLink,
                href: item.href,
                // target: "_blank",
                rel: "noreferrer",
              };

              const routeComponent = {
                component: Link,
                to: item.route,
              };

              return (
                <MKTypography
                  key={item.name}
                  {...(item.route ? routeComponent : linkComponent)}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  variant="button"
                  textTransform="capitalize"
                  minWidth={item.description ? "14rem" : "12rem"}
                  color={item.description ? "dark" : "text"}
                  fontWeight={item.description ? "bold" : "regular"}
                  py={item.description ? 1 : 0.625}
                  px={2}
                  sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                    borderRadius: borderRadius.md,
                    cursor: "pointer",
                    transition: "all 300ms linear",

                    "&:hover": {
                      backgroundColor: grey[200],
                      color: dark.main,

                      "& *": {
                        color: dark.main,
                      },
                    },
                  })}
                >
                  {item.description ? (
                    <MKBox>
                      {item.name}
                      <MKTypography
                        display="block"
                        variant="button"
                        color="text"
                        fontWeight="regular"
                        sx={{ transition: "all 300ms linear" }}
                      >
                        {item.description}
                      </MKTypography>
                    </MKBox>
                  ) : (
                    item.name
                  )}
                  {item.collapse && (
                    <Icon
                      fontSize="small"
                      sx={{ fontWeight: "normal", verticalAlign: "middle", mr: -0.5 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </MKTypography>
              );
            });
        }

        return template;
      })
      : null
  );

  // Dropdown menu for the nested dropdowns

  const nestedDropdownMenu = (
    <Popper
      anchorEl={nestedDropdown}
      popperRef={null}
      open={Boolean(nestedDropdown)}
      placement="right-start"
      transition
      style={{ zIndex: 10 }}
      onMouseEnter={() => {
        setNestedDropdown(nestedDropdownEl);
      }}
      onMouseLeave={() => {
        setNestedDropdown(null);
        setNestedDropdownName("");
        setDropdown(null);
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <MKBox ml={2.5} mt={-2.5} borderRadius="lg">
            <MKBox shadow="lg" borderRadius="lg" py={1.5} px={1} mt={2}>
              {renderNestedRoutes}
            </MKBox>
          </MKBox>
        </Grow>
      )}
    </Popper>
  );


  return (
    <>
      <OnMobile>
        <MKBox cladd display="flex" justifyContent="right" alignItems="center" bgColor="topNavColor">

          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
            <Grid item xs={2} sm={2} textAlign={'left'}>
              <Link to="http://meritechsolutions.com/" target="_blank">
                <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" ml={5}>
                  Meritech
                </MKTypography>
              </Link>
            </Grid>
            <Grid item xs={2} sm={2} px={1}>
              <Link to="https://monoz.io/" target="_blank">
                <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" ml={5} >
                  monoZ
                </MKTypography>
              </Link>
            </Grid>
            <Grid item xs={1} sm={1}>
              <Link to="https://www2.azenqos.com/" target="_blank">
                <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" ml={5} >
                  AZQ
                </MKTypography>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Link to="https://academy.meritech.co.in/" target="_blank">
                <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" ml={5} >
                  &nbsp; Meritech Academy
                </MKTypography>
              </Link>
            </Grid>
          </Grid>
        </MKBox>
      </OnMobile>
      <OnDesktop>
        <MKBox cladd display="flex" justifyContent="right" alignItems="center" bgColor="topNavColor">
          <Link to="http://meritechsolutions.com/" target="_blank">
            <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" mr={5}>
              Meritech
            </MKTypography>
          </Link>
          <Link to="https://monoz.io/" target="_blank">
            <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" mr={5} >
              monoZ
            </MKTypography>
          </Link>
          <Link to="https://academy.meritech.co.in/" target="_blank">
            <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" mr={5} >
              Meritech Academy
            </MKTypography>
          </Link>
          <Link to="https://www2.azenqos.com/" target="_blank">
            <MKTypography variant="p" fontWeight="small" color="white" textAlign="center" mr={5} >
              AZENQOS
            </MKTypography>
          </Link>
        </MKBox>
      </OnDesktop>

      <MKBox component="header" bgColor="headerNavColor" >
        <MKBox
          bgColor="headerNavColor"
          py={{ xs: 0, sm: 0, md: 1, lg: 1 }}
          id="dddddd"
          px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
          width={relative ? "100%" : "calc(100%)"}
          shadow={transparent ? "none" : "md"}
          color="dark"
          position={relative ? "relative" : "absolute"}
          left={0}
          zIndex={3}
          sx={{ borderRadius: "0px 0px 40px 40px" }}
        >
          <MKBox display="flex" justifyContent="space-between" alignItems="center">

            <Link to="/website">
              <MKBox component="img" src={logoimg} alt="Eoxys" pt={1} maxWidth="10rem" sx={{ width: "75%", height: "75%" }} />
            </Link>
            <MKBox
              color="dark"
              display={{ xs: "none", lg: "flex" }}
              ml="auto"
              fontWeight="medium"
              textTransform="capitalize"
              mr={center ? "auto" : 0}
            >
              {renderNavbarItems}
              <MKBox>
              </MKBox>
            </MKBox>
            <MKBox
              display={{ xs: "inline-block", lg: "none" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color={"white"}
              sx={{ cursor: "pointer" }}
              onClick={openMobileNavbar}
            >
              <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
            </MKBox>
          </MKBox>
          <MKBox
            bgColor={transparent ? "white" : "transparent"}
            shadow={transparent ? "lg" : "none"}
            borderRadius="xl"
            px={transparent ? 2 : 0}
          >
            {mobileView && <DefaultNavbarMobile routes={routes} open={mobileNavbar} />}
          </MKBox>
        </MKBox>
        {dropdownMenu}
        {nestedDropdownMenu}
        {/* </Container>     */}
      </MKBox>
    </>);
}

// Setting default values for the props of DefaultNavbar

DefaultNavbar.defaultProps = {
  // brand: "Material Kit 2",
  transparent: false,
  light: false,
  // action: false,
  sticky: false,
  fixed: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  // brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  // action: PropTypes.oneOfType([
  //   PropTypes.bool,
  //   PropTypes.shape({
  //     type: PropTypes.oneOf(["external", "internal"]).isRequired,
  //     route: PropTypes.string.isRequired,
  //     color: PropTypes.oneOf([
  //       "primary",
  //       "secondary",
  //       "info",
  //       "success",
  //       "warning",
  //       "error",
  //       "dark",
  //       "light",
  //       "default",
  //       "white",
  //     ]),
  //     label: PropTypes.string.isRequired,
  //   }),
  // ]),
  fixed: PropTypes.bool,
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
