import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { layoutGenerator } from 'react-break';
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import DefaultCard from "components/Card";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const layout = layoutGenerator({
    mobile: 0,
    // mobile: 550,
    // tablet: 768,
    desktop: 992,
});

const OnMobile = layout.is('mobile');
const OnDesktop = layout.is('desktop');

function defaultCarousel({ arraydata1, arraydata2, arraydata3, arraydata4, arraydata5, arraydata6, client, testimonial }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <MKBox component="section" justifyContent="centre">
            <Container>
                <OnMobile>
                    {(testimonial == "true") && (<Grid container item xs={12} md={12} lg={12}>
                        <Grid item xs={12} sm={12} md={4} >
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata1.map((step, index) => (
                                    <div key={step.label}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                footer={step.footer}
                                                image={step.imgPath}
                                                imagesize="5rem"
                                                heightValue="50rem"
                                                widthValue="35rem"
                                                hrValue="false"
                                                description={step.label}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                    </Grid>)}

                    {(client == "true") && (<Grid container item xs={12} md={12} lg={12} >
                        {/* {clientarr.map((step) => ( */}
                        <Grid item xs={12} md={4} lg={2} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata1.map((step, index) => (
                                    <div key={step.id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            // <DefaultCard
                                            //     bgcolor="#04293A"
                                            //     imagelink={step.imgPath}
                                            //     href={step.link}
                                            //     imagesize="7vh"
                                            //     imagewidthsize="7vh"
                                            //     heightValue="10rem"
                                            //     widthValue="7rem"
                                            //     hrValue="false"
                                            // />
                                            <MKBox
                                                component="a"
                                                href={step.link}
                                                target="_blank" justifyContent="center" textAlign="center" >
                                                <MKBox component="img" justifyContent="center" textAlign="center" m="auto" src={step.imgPath} alt={"clients"} minHeight="15vh"
                                                    width="100%" />
                                            </MKBox>
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                    </Grid>)}
                </OnMobile>
                <OnDesktop>
                    {(testimonial == "true") && (<Grid container item xs={12} md={12} lg={12}>
                        <Grid item xs={12} sm={12} md={4} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata1.map((step, index) => (
                                    <div key={step.label}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                footer={step.footer}
                                                image={step.imgPath}
                                                imagesize="5rem"
                                                heightValue="38rem"
                                                widthValue="30rem"
                                                hrValue="false"
                                                description={step.label}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        <Grid item xs={12} md={4} px={2}>

                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata2.map((step, index) => (
                                    <div key={step.label}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                footer={step.footer}
                                                image={step.imgPath}
                                                imagesize="5rem"
                                                heightValue="38rem"
                                                widthValue="30rem"
                                                hrValue="false"
                                                description={step.label}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        <Grid item xs={12} md={4} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata3.map((step, index) => (
                                    <div key={step.label}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            // <MKBox
                                            //     component="img"
                                            //     sx={{
                                            //         height: 255,
                                            //         display: "block",
                                            //         maxWidth: 400,
                                            //         overflow: "hidden",
                                            //         width: "100%"
                                            //     }}
                                            //     src={step.imgPath}
                                            //     alt={step.label}
                                            // />
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                footer={step.footer}
                                                image={step.imgPath}
                                                imagesize="5rem"
                                                heightValue="38rem"
                                                widthValue="30rem"
                                                hrValue="false"
                                                description={step.label}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                    </Grid>)}

                    {(client == "true") && (<Grid container item xs={12} md={12} lg={12} >
                        {/* {clientarr.map((step) => ( */}
                        <Grid item xs={12} sm={12} md={4} lg={2} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata1.map((step, index) => (
                                    <div key={step.id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                imagelink={step.imgPath}
                                                href={step.link}
                                                imagesize="7rem"
                                                heightValue="10rem"
                                                widthValue="10rem"
                                                hrValue="false"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        <Grid item xs={12} md={4} lg={2} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata2.map((step, index) => (
                                    <div key={step.id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                imagelink={step.imgPath}
                                                href={step.link}
                                                imagesize="7rem"
                                                heightValue="10rem"
                                                widthValue="10rem"
                                                hrValue="false"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        <Grid item xs={12} md={4} lg={2} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata3.map((step, index) => (
                                    <div key={step.id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                imagelink={step.imgPath}
                                                href={step.link}
                                                imagesize="7rem"
                                                heightValue="10rem"
                                                widthValue="10rem"
                                                hrValue="false"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        <Grid item xs={12} md={4} lg={2} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata4.map((step, index) => (
                                    <div key={step.id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                imagelink={step.imgPath}
                                                href={step.link}
                                                imagesize="7rem"
                                                heightValue="10rem"
                                                widthValue="10rem"
                                                hrValue="false"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        <Grid item xs={12} md={4} lg={2} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata5.map((step, index) => (
                                    <div key={step.id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                imagelink={step.imgPath}
                                                href={step.link}
                                                imagesize="7rem"
                                                heightValue="10rem"
                                                widthValue="10rem"
                                                hrValue="false"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        <Grid item xs={12} md={4} lg={2} px={2}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {arraydata6.map((step, index) => (
                                    <div key={step.id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <DefaultCard
                                                bgcolor="#04293A"
                                                imagelink={step.imgPath}
                                                href={step.link}
                                                imagesize="7rem"
                                                heightValue="10rem"
                                                widthValue="10rem"
                                                hrValue="false"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                        </Grid>
                        {/* ))} */}
                    </Grid>)}</OnDesktop>
            </Container>
        </MKBox>
    );
}

// Setting default props for the defaultCard
defaultCarousel.defaultProps = {
    arraydata1: [],
    arraydata2: [],
    arraydata3: [],
    bgcolor: "white",
    color: "info",
    description: "",
    title: "",
    image: "",
    imagesize: "",
    buttonlink: "",
    buttonvalue: "",
    borderradius: "xl",
    heightValue: "25rem",
    widthtValue: "20rem", client: "false", testimonial: "false", aboutus: "false",
};

// Typechecking props for the DefaultCounterCard
defaultCarousel.propTypes = {
    arraydata1: [],
    arraydata2: [],
    arraydata3: [],
    bgcolor: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "white",
    ]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "white",
    ]),
    count: PropTypes.number.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: "",
    imagesize: "",
    buttonlink: "",
    buttonvalue: "",
    borderradius: "",
    heightValue: "25rem",
    widthtValue: "20rem", client: "false", testimonial: "false", aboutus: "false",
};

export default defaultCarousel;
