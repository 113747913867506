import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultCard from "components/Card";

// Images
import aimlproductengimg from "assets/images/icons/prodE.png";
import cloudservesimg from "assets/images/icons/cloudE.png";
import gsap from 'gsap';
import { useEffect, useRef } from 'react';


const aimlpe = [
    {
        id: "1",
        name: "Embedded HW Design"
    },
    {
        id: "2",
        name: "Embedded SW Design"
    },
    {
        id: "3",
        name: "Enclosure ID Design"
    },
];
const aimlce = [
    {
        id: "1",
        name: "Dashboard App Development"
    },
    {
        id: "2",
        name: "Desktop App Development"
    },
    {
        id: "3",
        name: "Mobile App Development"
    },
];
function Service() {
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <MKBox component="section" mt={10} py={2} id='service'>
            <Container>
                <Grid container item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                        <MKTypography
                            variant="Overline"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="light"
                        // sx={({ breakpoints, typography: { size } }) => ({
                        //     [breakpoints.down("md")]: {
                        //         fontSize: size["xl"],
                        //     },
                        // })}
                        >
                            Eoxys
                        </MKTypography>
                        <MKTypography
                            variant="h2"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="bold"
                            ref={textRef}
                        >
                            Our Services
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} justifyContent="center" >
                        <MKTypography
                            variant="body1"
                            color="dark"
                            textAlign="justify"
                            fontWeight="regular"
                        >
                            We Take Pride In building your AIML solutions
                        </MKTypography>
                    </Grid>
                </Grid>
            </Container>
            <Container >
                <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
                    <Grid item xs={0} md={0} lg={1.5} px={2}></Grid>
                    <Grid item xs={12} md={12} lg={5} px={1}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="Product Engineering "
                            image={aimlproductengimg}
                            imagesize="10rem"
                            heightValue="35rem"
                            widthValue="20rem"
                            listitems={aimlpe}
                            description="With our XENO+ series ML SOM modules and eco-system components,
                            We help our customers to build their Battery powered
                            AIML/IoT devices in short time. With our specialized team, We can do custom HW design as per customers need."
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} px={1}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="Cloud Software Engineering"
                            image={cloudservesimg}
                            imagesize="10rem"
                            heightValue="35rem"
                            widthValue="20rem"
                            listitems={aimlce}
                            description="We help our clients to build their Cloud 
                            and Desktop applications with latest tech stack (Java, Spring, React, Angular, Qt) to reach
                            time-to-market at shorter time. "
                        />
                    </Grid>
                    <Grid item xs={0} md={0} lg={1.5} px={2}></Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Service;
