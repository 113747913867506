import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import DefaultCard from "components/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import hwdevkitimg from "assets/images/SVG/HW Dev Kit 3.svg";
import rmlmodelsimg from "assets/images/SVG/Ready ML Module 2.svg";
import sommodimg from "assets/images/SVG/SOM Module 2_1.svg";
import swdevsupportimg from "assets/images/SVG/SW Dev Support 2.svg";

import gsap from 'gsap';
import { useEffect, useRef } from 'react';
function Approach() {
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <MKBox component="section" mt={5} py={2} id='approach' >
            <Container>
                <Grid container item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                        <MKTypography
                            variant="Overline"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="light"
                        // sx={({ breakpoints, typography: { size } }) => ({
                        //     [breakpoints.down("md")]: {
                        //         fontSize: size["xl"],
                        //     },
                        // })}
                        >
                            Eoxys
                        </MKTypography>
                        <MKTypography
                            variant="h2"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="bold"
                            ref={textRef}
                        >
                            our approach
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} justifyContent="justify" >
                        <MKTypography
                            variant="body1"
                            color="dark"
                            textAlign="justify"
                            fontWeight="regular"
                        >
                            Focus on offering all eco-system components required
                            for our clients to maximize their time-to-market in bringing
                            out their Edge AIML/IoT based devices. These eco-system components
                            include AIML SOM modules, HW development kits for POC testing/validation,
                            Ready ML models to ease model training and SW development support components
                            such as XENO+ SDK, FOTA server and Customizable IoT Dashboard.
                        </MKTypography>
                    </Grid>
                </Grid>
            </Container>
            <Container >
                <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
                    <Grid item xs={12} sm={12} md={6} lg={3} px={2}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="SOM Modules"
                            color="dark"
                            hrValue="false"
                            image={sommodimg}
                            imagesize="9rem"
                            heightValue="30rem"
                            widthValue="20rem"
                            description="Edge AIML SOM helps ML classifications at the edge for
                             diverse industries, such as Industrial, Manufacturing, Automotive
                             , and Healthcare."
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} px={2} sx={{ borderColor: "success" }}  >
                        <DefaultCard
                            bgcolor="#04293A"
                            title="HW Dev Kits"
                            image={hwdevkitimg}
                            imagesize="9rem"
                            imagewidthsize="12rem"
                            heightValue="30rem"
                            widthValue="25rem"
                            description="The HW development kits like Ready-to-Use AIML modules and 
                            AIML Dev Kits helps clients to carry-out quick POCs of their
                            target applications."
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} px={2}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="SW Dev Support "
                            image={swdevsupportimg}
                            imagesize="9rem"
                            heightValue="30rem"
                            widthValue="20rem"
                            // description="The XENO+ SDK helps SW developers with Networking and AIML APIs
                            //  to ease their SW development. We also offer readily usable FOTA 
                            //  server for device auth and Firmware Over The Air upgradation of application binaries and ML models."
                            description="The XENO+ SDK simplifies development with Networking and AIML APIs, 
                            plus a FOTA server for secure device authentication and over-the-air updates of binaries and models."
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} px={2}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="Ready ML Models"
                            image={rmlmodelsimg}
                            imagesize="9rem"
                            heightValue="30rem"
                            widthValue="20rem"
                            description="The ready ML models that we have for selected applications 
                            enables the clients to quickly port and test these models into their 
                            devices and fine tune further as per their end application needs."
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Approach;
