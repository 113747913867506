import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { layoutGenerator } from 'react-break';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultCard from "components/Card";
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

const layout = layoutGenerator({
    mobile: 0,
    // phablet: 550,
    // tablet: 768,
    desktop: 992,
});

const OnMobile = layout.is('mobile');
const OnDesktop = layout.is('desktop');

const news_pr = window.news_press_details;
function News() {
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <>
            <MKBox component="section" mt={10} py={2} id='news' >
                {/* <Container>
                    <Grid container item xs={12} lg={12} justifyContent="center" mx={1}>
                        <MKTypography
                            variant="h4"
                            color="dark"
                            mt={-6}
                            mb={1}
                            textTransform="uppercase"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            News & Press Releases
                        </MKTypography>
                    </Grid>
                    <Grid container item xs={12} lg={12} justifyContent="center" mx={1}>
                        <MKTypography
                            variant="body1"
                            color="dark"
                            textAlign="center"
                        >
                            Connecting People, Ideas, and Opportunities.
                        </MKTypography>
                    </Grid>
                </Container> */}
                <Container>
                    <Grid container item xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                            <MKTypography
                                variant="Overline"
                                color="dark"
                                textTransform="capitalize"
                                fontWeight="light"
                            // sx={({ breakpoints, typography: { size } }) => ({
                            //     [breakpoints.down("md")]: {
                            //         fontSize: size["xl"],
                            //     },
                            // })}
                            >
                                Eoxys
                            </MKTypography>
                            <MKTypography
                                variant="h2"
                                color="dark"
                                textTransform="capitalize"
                                fontWeight="bold"
                                ref={textRef}
                            >
                                News & Press Releases
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={8} lg={8} justifyContent="center" >
                            <MKTypography
                                variant="body1"
                                color="dark"
                                textAlign="justify"
                                fontWeight="regular"
                            >
                                Connecting People, Ideas, and Opportunities.
                            </MKTypography>
                        </Grid>
                    </Grid>
                </Container>
                <Container >
                    <OnMobile>
                        <Grid container item xs={12} md={12} lg={12} pt={2} sx={{ mx: "auto" }}>

                            <Grid item xs={12} md={6} lg={6} px={2}>
                                <DefaultCard
                                    bgcolor="#04293A"
                                    heightValue="45rem"
                                    widthValue="20rem"
                                    newslistitems={news_pr.news_release}
                                    hrValue="false"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} px={2}>
                                <DefaultCard
                                    bgcolor="#04293A"
                                    heightValue="45rem"
                                    widthValue="20rem"
                                    newslistitems={news_pr.press_release}
                                    hrValue="false"
                                />
                            </Grid>
                        </Grid>
                    </OnMobile>


                    <OnDesktop>
                        <Grid container item xs={12} md={12} lg={12} pt={2} sx={{ mx: "auto" }}>
                            <Grid item xs={12} md={6} lg={6} px={2}>
                                <DefaultCard
                                    bgcolor="#04293A"
                                    heightValue="38rem"
                                    widthValue="20rem"
                                    newslistitems={news_pr.news_release}
                                    hrValue="false"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} px={2}>
                                <DefaultCard
                                    bgcolor="#04293A"
                                    heightValue="38rem"
                                    widthValue="20rem"
                                    newslistitems={news_pr.press_release}
                                    hrValue="false"
                                />
                            </Grid></Grid>
                    </OnDesktop>

                </Container>
            </MKBox>

        </>

    );
}

export default News;
