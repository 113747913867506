// @mui material components
// import Card from "@mui/material/Card";
import * as React from "react";

import ScrollToTop from "react-scroll-to-top";
// Material Kit 2 React components
import DefaultFooter from "components/Footer";
import DefaultNavbar from "components/Header";
import MKBox from "components/MKBox";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import colorValue from "assets/theme/base/colors";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Images
import bgImage from "assets/images/gifs/ai-revised2.gif";
import MKTypography from "components/MKTypography";


function Solution_page() {
    console.log("bgImage ", bgImage);
    return (
        <div>
            <ScrollToTop smooth color="#000000" />
            {/* header */}
            < MKBox pt={5} px={0} mt={-5} >
                <DefaultNavbar
                    routes={routes}
                    sticky
                /></MKBox>
            {console.log(colorValue.headerColor)}
            <MKBox component="section" my={15} py={2} id="store">
                <Container>

                    <Grid container item xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={4} lg={4} justifyContent="center">
                            <MKTypography
                                variant="Overline"
                                color="dark"
                                textTransform="capitalize"
                                fontWeight="light"
                                id="store"
                            >
                                Eoxys Systems India Pvt Ltd.
                            </MKTypography>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" my={15} py={2} id="store">
                <Container>

                    <Grid container item xs={12} md={12} lg={12}>
                     
                        <Grid item xs={12} md={12} lg={12} justifyContent="center">
                            <MKTypography variant="h5" textAlign="justify"
                                fontWeight="medium" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                XENO+™ WiFi+BLE Nano ML Module product is unavailable in board variant. It is now available in SOM variant. Please&nbsp;
                                <MKTypography
                                    component={Link}
                                    to="/website/productpage/wifisom"
                                    variant="h6"
                                    fontWeight="regular"
                                    color="headerColor"
                                    textTransform="capitalize"
                                > click here &nbsp;
                                </MKTypography>to know more.
                            </MKTypography>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            {/* footer */}
            <MKBox pt={5} px={0} mt={5}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
            <ScrollToTop smooth color="#454545" backgroundColor="#000000" />
        </div>
    );
}

export default Solution_page;
