import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import equbotlogo from "assets/images/other_image/logo/equbot.jpg";
import iitmlogo from "assets/images/other_image/logo/iitm.jpg";
import iiscblogo from "assets/images/other_image/logo/iiscb1.png";
import cumilogo from "assets/images/other_image/logo/cumi.png";
import itclogo from "assets/images/clientslogo/itc2.png";
import trinsapplogo from "assets/images/other_image/logo/tringapps.png";
import reckittlogo from "assets/images/other_image/logo/reckitt.png";
import uniofmeblogo from "assets/images/other_image/logo/Uom.png";
import daimlerlogo from "assets/images/other_image/logo/daimler.png";


import SwipeableTextMobileStepper from "components/Carousel";
import gsap from 'gsap';
import { useEffect, useRef } from 'react';
const images = [
    {
        id: 1,
        imgPath: equbotlogo,
        link: "https://equbot.com/",
    },
    {
        id: 2,
        imgPath: iitmlogo,
        link: "https://www.iitm.ac.in/",
    },
    {
        id: 3,
        imgPath: iiscblogo,
        link: "https://iisc.ac.in/",
    },
    {
        id: 4,
        imgPath: cumilogo,
        link: "https://www.cumi-murugappa.com/",
    },
    {
        id: 5,
        imgPath: itclogo,
        link: "https://www.itcportal.com/about-itc/research-and-development.aspx",
    },
    {
        id: 6,
        imgPath: trinsapplogo,
        link: "https://tringapps.com/",
    },
    {
        id: 7,
        imgPath: reckittlogo,
        link: "https://www.reckitt.com/",
    },
    {
        id: 8,
        imgPath: uniofmeblogo,
        link: "https://www.unimelb.edu.au/",
    },
    {
        id: 9,
        imgPath: daimlerlogo,
        link: "https://www.daimlertruck.com/en",
    },
];
const images2 = [
    {
        id: 2,
        imgPath: iitmlogo,
        link: "https://www.iitm.ac.in/",
    },
    {
        id: 3,
        imgPath: iiscblogo,
        link: "https://iisc.ac.in/",
    },
    {
        id: 4,
        imgPath: cumilogo,
        link: "https://www.cumi-murugappa.com/",
    },
    {
        id: 5,
        imgPath: itclogo,
        link: "https://www.itcportal.com/about-itc/research-and-development.aspx",
    },
    {
        id: 6,
        imgPath: trinsapplogo,
        link: "https://tringapps.com/",
    },
    {
        id: 7,
        imgPath: reckittlogo,
        link: "https://www.reckitt.com/",
    },
    {
        id: 8,
        imgPath: uniofmeblogo,
        link: "https://www.unimelb.edu.au/",
    },
    {
        id: 9,
        imgPath: daimlerlogo,
        link: "https://www.daimlertruck.com/en",
    },
    {
        id: 1,
        imgPath: equbotlogo,
        link: "https://equbot.com/",
    },
];
const images3 = [
    {
        id: 3,
        imgPath: iiscblogo,
        link: "https://iisc.ac.in/",
    },
    {
        id: 4,
        imgPath: cumilogo,
        link: "https://www.cumi-murugappa.com/",
    },
    {
        id: 5,
        imgPath: itclogo,
        link: "https://www.itcportal.com/about-itc/research-and-development.aspx",
    },
    {
        id: 6,
        imgPath: trinsapplogo,
        link: "https://tringapps.com/",
    },
    {
        id: 7,
        imgPath: reckittlogo,
        link: "https://www.reckitt.com/",
    },
    {
        id: 8,
        imgPath: uniofmeblogo,
        link: "https://www.unimelb.edu.au/",
    },
    {
        id: 9,
        imgPath: daimlerlogo,
        link: "https://www.daimlertruck.com/en",
    },
    {
        id: 1,
        imgPath: equbotlogo,
        link: "https://equbot.com/",
    },
    {
        id: 2,
        imgPath: iitmlogo,
        link: "https://www.iitm.ac.in/",
    },
];
const images4 = [
    {
        id: 4,
        imgPath: cumilogo,
        link: "https://www.cumi-murugappa.com/",
    },
    {
        id: 5,
        imgPath: itclogo,
        link: "https://www.itcportal.com/about-itc/research-and-development.aspx",
    },
    {
        id: 6,
        imgPath: trinsapplogo,
        link: "https://tringapps.com/",
    },
    {
        id: 7,
        imgPath: reckittlogo,
        link: "https://www.reckitt.com/",
    },
    {
        id: 8,
        imgPath: uniofmeblogo,
        link: "https://www.unimelb.edu.au/",
    },
    {
        id: 9,
        imgPath: daimlerlogo,
        link: "https://www.daimlertruck.com/en",
    },
    {
        id: 1,
        imgPath: equbotlogo,
        link: "https://equbot.com/",
    },
    {
        id: 2,
        imgPath: iitmlogo,
        link: "https://www.iitm.ac.in/",
    },
    {
        id: 3,
        imgPath: iiscblogo,
        link: "https://iisc.ac.in/",
    },
];
const images5 = [
    {
        id: 5,
        imgPath: itclogo,
        link: "https://www.itcportal.com/about-itc/research-and-development.aspx",
    },
    {
        id: 6,
        imgPath: trinsapplogo,
        link: "https://tringapps.com/",
    },
    {
        id: 7,
        imgPath: reckittlogo,
        link: "https://www.reckitt.com/",
    },
    {
        id: 8,
        imgPath: uniofmeblogo,
        link: "https://www.unimelb.edu.au/",
    },
    {
        id: 9,
        imgPath: daimlerlogo,
        link: "https://www.daimlertruck.com/en",
    },
    {
        id: 1,
        imgPath: equbotlogo,
        link: "https://equbot.com/",
    },
    {
        id: 2,
        imgPath: iitmlogo,
        link: "https://www.iitm.ac.in/",
    },
    {
        id: 3,
        imgPath: iiscblogo,
        link: "https://iisc.ac.in/",
    },
    {
        id: 4,
        imgPath: cumilogo,
        link: "https://www.cumi-murugappa.com/",
    },
];
const images6 = [
    {
        id: 6,
        imgPath: trinsapplogo,
        link: "https://tringapps.com/",
    },
    {
        id: 7,
        imgPath: reckittlogo,
        link: "https://www.reckitt.com/",
    },
    {
        id: 8,
        imgPath: uniofmeblogo,
        link: "https://www.unimelb.edu.au/",
    },
    {
        id: 9,
        imgPath: daimlerlogo,
        link: "https://www.daimlertruck.com/en",
    },
    {
        id: 1,
        imgPath: equbotlogo,
        link: "https://equbot.com/",
    },
    {
        id: 2,
        imgPath: iitmlogo,
        link: "https://www.iitm.ac.in/",
    },
    {
        id: 3,
        imgPath: iiscblogo,
        link: "https://iisc.ac.in/",
    },
    {
        id: 4,
        imgPath: cumilogo,
        link: "https://www.cumi-murugappa.com/",
    },
    {
        id: 5,
        imgPath: itclogo,
        link: "https://www.itcportal.com/about-itc/research-and-development.aspx",
    },
];

function Client() {
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <MKBox component="section" mt={10} py={2} id='client' >
            <Container>
                <Grid container item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                        <MKTypography
                            variant="Overline"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="light"
                        >
                            Eoxys
                        </MKTypography>
                        <MKTypography
                            variant="h2"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="bold"
                            ref={textRef}
                        >
                            Our Clients
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} justifyContent="center" >
                        <MKTypography
                            variant="body1"
                            color="dark"
                            textAlign="justify"
                            fontWeight="regular"
                        >
                            Turning Ideas Into Success Stories
                        </MKTypography>
                    </Grid>
                </Grid>
            </Container>
            <Container >
                <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
                    <SwipeableTextMobileStepper arraydata1={images} arraydata2={images2} arraydata3={images3} arraydata4={images4} arraydata5={images5} arraydata6={images6} client="true" />
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Client;
