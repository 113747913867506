import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultCard from "components/Card";

// Images
import nuvotonLogo from "assets/images/partnerlogo/nuvoton.png";
import InnoPhaseIoTLogo from "assets/images/partnerlogo/InnoPhaseIoT.png";
import RenesasElectronicsLogo from "assets/images/partnerlogo/RenesasElectronicslogo.png";
import syntiantLogo from "assets/images/partnerlogo/og.png";
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

function Partner() {
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <MKBox component="section" mt={10} py={2} id='partner'>
            <Container>
                <Grid container item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                        <MKTypography
                            variant="Overline"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="light"
                        // sx={({ breakpoints, typography: { size } }) => ({
                        //     [breakpoints.down("md")]: {
                        //         fontSize: size["xl"],
                        //     },
                        // })}
                        >
                            Eoxys
                        </MKTypography>
                        <MKTypography
                            variant="h2"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="bold"
                            ref={textRef}
                        >
                            Our Partners
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} justifyContent="center" >
                        <MKTypography
                            variant="body1"
                            color="dark"
                            textAlign="justify"
                            fontWeight="regular"
                        >
                            Empowering Progress Together Building Stronger Futures with Our Partners.
                        </MKTypography>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
                    {/* <Grid item xs={0} md={0} lg={1.5} px={2}></Grid> */}
                    <Grid item xs={12} md={6} lg={3} px={2}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="InnophaseIoT"
                            image={InnoPhaseIoTLogo}
                            imagesize="3rem"
                            imagewidthsize="10rem"
                            heightValue="10rem"
                            widthValue="20rem"
                            buttonlink="https://innophaseiot.com/talaria-two-modules/"
                            buttonvalue="Find out more"
                            target_blank="true"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} px={2}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="Syntiant"
                            image={syntiantLogo}
                            imagesize="3rem"
                            imagewidthsize="13rem"
                            heightValue="10rem"
                            widthValue="20rem"
                            buttonlink="https://www.syntiant.com/"
                            buttonswitch="true"
                            buttonvalue="Find out more"
                            target_blank="true"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} px={2}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="Renesas"
                            image={RenesasElectronicsLogo}
                            imagesize="3rem"
                            imagewidthsize="10rem"
                            heightValue="10rem"
                            widthValue="20rem"
                            buttonlink="https://www.renesas.com/us/en"
                            buttonvalue="Find out more"
                            target_blank="true"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} px={2}>
                        <DefaultCard
                            bgcolor="#04293A"
                            title="Nuvoton "
                            image={nuvotonLogo}
                            imagesize="3rem"
                            imagewidthsize="10rem"
                            heightValue="10rem"
                            widthValue="20rem"
                            buttonlink="https://www.nuvoton.com/"
                            buttonvalue="Find out more"
                            target_blank="true"
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Partner;
