import * as React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Icon from "@mui/material/Icon";

import '../../style.css';

function defaultCard({ href, colorValue, title, image, imagelink, imagesize, imagewidthsize, description, 
    buttonlink, borderradius, hrValue, footer, heightValue, widthValue, listitems, newslistitems,target_blank }) {
    return (

        <>
            {buttonlink &&
                (
                (target_blank == "true") ? 
                <Link to={buttonlink} target="_blank">
                    < MKBox p={2} my={3} textAlign="justify" bgColor="cardColorValue" variant="outlined" lineHeight={1} height={heightValue} maxwidth={widthValue}
                        borderRadius={borderradius} shadow="xl"
                        borderColor="success"

                    // sx={{
                    //     border: 0, borderColor: "#4CAF50",
                    //     '&:hover': {
                    //         opacity: [0.9, 0.8, 0.7],
                    //         boxShadow: "rgb(33, 139, 209,0.3) 0px 0px 0px 3px, rgb(33, 139, 209,0.25) 0px 0px 0px 6px,  rgb(33, 139, 209,0.2) 0px 0px 0px 9px, rgb(33, 139, 209,0.15) 0px 0px 0px 12px, rgb(33, 139, 209,0.1) 0px 0px 0px 15px,rgb(33, 139, 209,0.05) 0px 0px 0px 18px;"
                    //     }, imagewidthsize
                    // }}
                    >

                        {
                            image && (<MKBox textAlign="center" justifyContent="center" >
                                <MKBox component="img" justifyContent="center" textAlign="center" src={image} alt={title}
                                    minHeight={imagesize} height={imagesize}
                                    width={imagewidthsize ? imagewidthsize : imagesize}
                                    maxWidth={imagewidthsize ? imagewidthsize : imagesize} m={"auto"} />
                            </MKBox>)
                        }

                        {
                            imagelink && (<MKBox
                                component="a"
                                href={href}
                                target="_blank" textAlign="center" justifyContent="center" >
                                <MKBox component="img" justifyContent="center" textAlign="center" src={imagelink} alt={title} minHeight={imagesize}
                                    width={imagewidthsize ? imagewidthsize : imagesize} maxWidth={imagewidthsize ? imagewidthsize : imagesize} height={imagesize} />
                            </MKBox>)
                        }
                        {
                            title && (
                                <Grid container justifyContent="center">
                                    <Grid item xs={8} md={12} >
                                        <MKTypography variant="h5" fontWeight="medium" color={colorValue} textAlign="center" mt={2} mb={1}>
                                            {title}
                                        </MKTypography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <MKBox textAlign="center" sx={{ m: "2rem" }}  >
                            {(hrValue == "true") && (<hr color="#000000" />)}</MKBox>
                        {
                            footer && (
                                <Grid container>
                                    <Grid item xs={15} md={7} sx={{ ml: "auto", mb: 3 }}>
                                        <MKTypography variant="body2" textAlign="justify" color="headerColor" fontWeight="bold">
                                            - {footer}
                                        </MKTypography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            description && (
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular">
                                    {description}
                                </MKTypography>
                            )
                        }
                        <br />

                        {
                            listitems &&
                            (
                                listitems.map((step) => (
                                    <>
                                        <Grid container xs={12} md={12} lg={12} textAlign="justify" justifyContent="justify">
                                            <Grid item xs={2} md={2} >
                                                <MKTypography variant="h6" m='auto' mr={2} textAlign="right" justifyContent="centre" fontSize="1" color="dark"
                                                    fontWeight="regular" key={step.id}> <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon></MKTypography>
                                            </Grid>
                                            <Grid item xs={10} md={10} >
                                                <MKTypography variant="h5" m='auto' fontSize="1rem" justifyContent="centre" color="dark"
                                                    fontWeight="bold" key={step.id}>{step.name}</MKTypography>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            )
                        }
                        {
                            newslistitems &&
                            (
                                newslistitems.map((step) => (
                                    <>
                                        <Grid container xs={12} md={12} lg={12} textAlign="justify" justifyContent="justify">
                                            <Grid item xs={1} md={1} >
                                                <MKTypography variant="h6" my={1} justifyContent="centre" color="dark"
                                                    fontWeight="regular" key={step.id}><FiberManualRecordIcon /> </MKTypography>
                                            </Grid>
                                            <Grid item xs={11} md={11} >
                                                <Link to={step.link} target="_blank" key={step.id} >
                                                    <MKTypography variant="h6" my={1} color="listFontColor"
                                                        fontWeight="medium" key={step.id}>{step.title}</MKTypography>
                                                </Link>

                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            )
                        }
                    </MKBox >
                </Link> :
                <Link to={buttonlink}>
                    < MKBox p={2} my={3} textAlign="justify" bgColor="cardColorValue" variant="outlined" lineHeight={1} height={heightValue} maxwidth={widthValue}
                        borderRadius={borderradius} shadow="xl"
                        borderColor="success"

                    // sx={{
                    //     border: 0, borderColor: "#4CAF50",
                    //     '&:hover': {
                    //         opacity: [0.9, 0.8, 0.7],
                    //         boxShadow: "rgb(33, 139, 209,0.3) 0px 0px 0px 3px, rgb(33, 139, 209,0.25) 0px 0px 0px 6px,  rgb(33, 139, 209,0.2) 0px 0px 0px 9px, rgb(33, 139, 209,0.15) 0px 0px 0px 12px, rgb(33, 139, 209,0.1) 0px 0px 0px 15px,rgb(33, 139, 209,0.05) 0px 0px 0px 18px;"
                    //     }, imagewidthsize
                    // }}
                    >

                        {
                            image && (<MKBox textAlign="center" justifyContent="center" >
                                <MKBox component="img" justifyContent="center" textAlign="center" src={image} alt={title}
                                    minHeight={imagesize} height={imagesize}
                                    width={imagewidthsize ? imagewidthsize : imagesize}
                                    maxWidth={imagewidthsize ? imagewidthsize : imagesize} m={"auto"} />
                            </MKBox>)
                        }

                        {
                            imagelink && (<MKBox
                                component="a"
                                href={href}
                                target="_blank" textAlign="center" justifyContent="center" >
                                <MKBox component="img" justifyContent="center" textAlign="center" src={imagelink} alt={title} minHeight={imagesize}
                                    width={imagewidthsize ? imagewidthsize : imagesize} maxWidth={imagewidthsize ? imagewidthsize : imagesize} height={imagesize} />
                            </MKBox>)
                        }
                        {
                            title && (
                                <Grid container justifyContent="center">
                                    <Grid item xs={8} md={12} >
                                        <MKTypography variant="h5" fontWeight="medium" color={colorValue} textAlign="center" mt={2} mb={1}>
                                            {title}
                                        </MKTypography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <MKBox textAlign="center" sx={{ m: "2rem" }}  >
                            {(hrValue == "true") && (<hr color="#000000" />)}</MKBox>
                        {
                            footer && (
                                <Grid container>
                                    <Grid item xs={15} md={7} sx={{ ml: "auto", mb: 3 }}>
                                        <MKTypography variant="body2" textAlign="justify" color="headerColor" fontWeight="bold">
                                            - {footer}
                                        </MKTypography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            description && (
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular">
                                    {description}
                                </MKTypography>
                            )
                        }
                        <br />

                        {
                            listitems &&
                            (
                                listitems.map((step) => (
                                    <>
                                        <Grid container xs={12} md={12} lg={12} textAlign="justify" justifyContent="justify">
                                            <Grid item xs={2} md={2} >
                                                <MKTypography variant="h6" m='auto' mr={2} textAlign="right" justifyContent="centre" fontSize="1" color="dark"
                                                    fontWeight="regular" key={step.id}> <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon></MKTypography>
                                            </Grid>
                                            <Grid item xs={10} md={10} >
                                                <MKTypography variant="h5" m='auto' fontSize="1rem" justifyContent="centre" color="dark"
                                                    fontWeight="bold" key={step.id}>{step.name}</MKTypography>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            )
                        }
                        {
                            newslistitems &&
                            (
                                newslistitems.map((step) => (
                                    <>
                                        <Grid container xs={12} md={12} lg={12} textAlign="justify" justifyContent="justify">
                                            <Grid item xs={1} md={1} >
                                                <MKTypography variant="h6" my={1} justifyContent="centre" color="dark"
                                                    fontWeight="regular" key={step.id}><FiberManualRecordIcon /> </MKTypography>
                                            </Grid>
                                            <Grid item xs={11} md={11} >
                                                <Link to={step.link} target="_blank" key={step.id} >
                                                    <MKTypography variant="h6" my={1} color="listFontColor"
                                                        fontWeight="medium" key={step.id}>{step.title}</MKTypography>
                                                </Link>

                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            )
                        }
                    </MKBox >
                </Link>)
            }
            {!buttonlink &&
                (
                    < MKBox p={2} my={3} textAlign="justify" bgColor="cardColorValue" variant="outlined" lineHeight={1} height={heightValue} maxwidth={widthValue}
                        borderRadius={borderradius} shadow="xl"
                        borderColor="success"

                    // sx={{
                    //     border: 0, borderColor: "#4CAF50",
                    //     '&:hover': {
                    //         opacity: [0.9, 0.8, 0.7],
                    //         boxShadow: "rgb(33, 139, 209,0.3) 0px 0px 0px 3px, rgb(33, 139, 209,0.25) 0px 0px 0px 6px,  rgb(33, 139, 209,0.2) 0px 0px 0px 9px, rgb(33, 139, 209,0.15) 0px 0px 0px 12px, rgb(33, 139, 209,0.1) 0px 0px 0px 15px,rgb(33, 139, 209,0.05) 0px 0px 0px 18px;"
                    //     }, imagewidthsize
                    // }}
                    >

                        {
                            image && (<MKBox textAlign="center" justifyContent="center" >
                                <MKBox component="img" justifyContent="center" textAlign="center" src={image} alt={title}
                                    minHeight={imagesize} height={imagesize}
                                    width={imagewidthsize ? imagewidthsize : imagesize}
                                    maxWidth={imagewidthsize ? imagewidthsize : imagesize} m={"auto"} />
                            </MKBox>)
                        }

                        {
                            imagelink && (<MKBox
                                component="a"
                                href={href}
                                target="_blank" textAlign="center" justifyContent="center" >
                                <MKBox component="img" justifyContent="center" textAlign="center" src={imagelink} alt={title} minHeight={imagesize}
                                    width={imagewidthsize ? imagewidthsize : imagesize} maxWidth={imagewidthsize ? imagewidthsize : imagesize} height={imagesize} />
                            </MKBox>)
                        }
                        {
                            title && (
                                <Grid container justifyContent="center">
                                    <Grid item xs={8} md={12} >
                                        <MKTypography variant="h5" fontWeight="medium" color={colorValue} textAlign="center" mt={2} mb={1}>
                                            {title}
                                        </MKTypography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <MKBox textAlign="center" sx={{ m: "2rem" }}  >
                            {(hrValue == "true") && (<hr color="#000000" />)}</MKBox>
                        {
                            footer && (
                                <Grid container>
                                    <Grid item xs={15} md={7} sx={{ ml: "auto", mb: 3 }}>
                                        <MKTypography variant="body2" textAlign="justify" color="headerColor" fontWeight="bold">
                                            - {footer}
                                        </MKTypography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            description && (
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular">
                                    {description}
                                </MKTypography>
                            )
                        }
                        <br />

                        {
                            listitems &&
                            (
                                listitems.map((step) => (
                                    <>
                                        <Grid container xs={12} md={12} lg={12} textAlign="justify" justifyContent="justify">
                                            <Grid item xs={2} md={2} >
                                                <MKTypography variant="h6" m='auto' mr={2} textAlign="right" justifyContent="centre" fontSize="1" color="dark"
                                                    fontWeight="regular" key={step.id}> <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon></MKTypography>
                                            </Grid>
                                            <Grid item xs={10} md={10} >
                                                <MKTypography variant="h5" m='auto' fontSize="1rem" justifyContent="centre" color="dark"
                                                    fontWeight="bold" key={step.id}>{step.name}</MKTypography>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            )
                        }
                        {
                            newslistitems &&
                            (
                                newslistitems.map((step) => (
                                    <>
                                        <Grid container xs={12} md={12} lg={12} textAlign="justify" justifyContent="justify">
                                            <Grid item xs={1} md={1} >
                                                <MKTypography variant="h6" my={1} justifyContent="centre" color="dark"
                                                    fontWeight="regular" key={step.id}><FiberManualRecordIcon /> </MKTypography>
                                            </Grid>
                                            <Grid item xs={11} md={11} >
                                                <Link to={step.link} target="_blank" key={step.id} >
                                                    <MKTypography variant="h6" my={1} color="listFontColor"
                                                        fontWeight="medium" key={step.id}>{step.title}</MKTypography>
                                                </Link>

                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            )
                        }
                    </MKBox >)
            }
        </>
    );
}

// Setting default props for the defaultCard
defaultCard.defaultProps = {
    bgColor: "dark",
    colorValue: "headerColor",
    description: "",
    title: "",
    image: "",
    imagelink: "",
    imagesize: "",
    imagewidthsize: "",
    buttonlink: "",
    borderradius: "xl",
    heightValue: "25rem",
    widthValue: "20rem",
    hrValue: "false",
    footer: "",
    listbool: "false",
    listitems: [],
    newslistitems: [],
    href: "",
    target_blank:"false",
};

// Typechecking props for the DefaultCounterCard
defaultCard.propTypes = {
    bgColor: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "headerColor",
        "listFontColor",
        "topNavColor",
    ]),
    colorValue: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "headerColor",
        "listFontColor",
        "topNavColor",

    ]),
    count: PropTypes.number.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: "",
    imagelink: "",
    imagesize: "",
    imagewidthsize: "",
    buttonlink: "",
    borderradius: "",
    heightValue: "25rem",
    widthValue: "20rem",
    hrValue: "true",
    footer: "",
    listbool: "false",
    listitems: [],
    newslistitems: [],
    href: "",
    target_blank:"false",
};

export default defaultCard;
