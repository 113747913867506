import { combineReducers } from "@reduxjs/toolkit";
import { applyMiddleware, createStore } from "redux";
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import generalReducer from './generalStore/reduce';

export const rootReducer = combineReducers({
    general: generalReducer,
  });
  



const store = createStore(rootReducer,applyMiddleware(logger,thunk));

export default store