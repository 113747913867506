import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "App";
import { Provider } from 'react-redux';
import store from './states/store';

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <HashRouter>
    <Provider store={store}>
      <App  />
    </Provider>
  </HashRouter>
);
