import ScrollToTop from "react-scroll-to-top";
import DefaultFooter from "components/Footer";
import DefaultNavbar from "components/Header";
import MKBox from "components/MKBox";
//Website Sections
import { useTheme } from "@mui/material/styles";
import aboutus2img from "assets/images/other_image/abouts12.png";
import aboutusimg from "assets/images/other_image/aboutus.png";
import Approach from "pages/Website/sections/Approach";
import FlowChart from "pages/Website/sections/FlowChart";
import Product from "pages/Website/sections/Product";
import About from "./sections/About";
import Client from "./sections/Client";
import ContactUS from "./sections/ContactUS";
import Event from "./sections/Event";
import News from "./sections/News";
import CaseStudiesVideo from "./sections/CaseStudiesVideo";
import Service from "./sections/Service";
import Testimonial from "./sections/Testimonial";

// import colorValue from "assets/theme/base/colors";
import { layoutGenerator } from 'react-break';
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Images
import animationData from 'assets/json/banner 2.json';
import Lottie from 'react-lottie';
import Partner from "./sections/Partner";
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const images = [
  {
    id: 1,
    imgPath: aboutusimg,
  },
  {
    id: 2,
    imgPath: aboutus2img,
  },
];

const layout = layoutGenerator({
  mobile: 0,
  // phablet: 550,
  // tablet: 768,
  desktop: 992,
});

const OnMobile = layout.is('mobile');
const OnDesktop = layout.is('desktop');
function Website() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const sectionsRef = useRef([]);

  useEffect(() => {
    sectionsRef.current.forEach(section => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: section,
            start: 'top 80%',
            toggleActions: 'play none none none',
          },
        }
      );
    });
  }, []);


  const components = [
    {
      id: 0,
      component:
        <Approach id="approach" />
    },
    {
      id: 1,
      component: <FlowChart id="flow_chart" />
    },
    {
      id: 2,
      component:
        <Product id="product" />
    },
    {
      id: 3,
      component: <>
        <OnMobile>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                    height="11rem" width="24.5rem"
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </OnMobile>
        <OnDesktop>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                    minHeight="75vh"
                    width="100%"
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </OnDesktop></>
    },
    {
      id: 4,
      component:
        <About id="aboutus" />
    },
    {
      id: 5,
      component:
        <Service id="service" />
    },
    {
      id: 6,
      component:
        <Client id="client" />
    },
    {
      id: 7,
      component:
        <Partner id="partner" />
    },
    {
      id: 8,
      component:

        <Testimonial id="testimonaial" />
    },
    {
      id: 9,
      component:
        <>
          <News id="news" />
          <Event /></>
    },
    {
      id: 10,
      component:
        <CaseStudiesVideo />
    },
    {
      id: 11,
      component:
        <ContactUS id="contactus" />
    },
  ]

  return (
    <div>
      <ScrollToTop smooth color="#ffffff" />
      {/* header */}
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <OnMobile>
        <Lottie
          options={defaultOptions}
          minHeight="120vh"
          width="100%"
          style={{
            marginTop: "2.8rem"
          }}
          m={5}
        />

      </OnMobile>

      <OnDesktop>
        <Lottie
          options={defaultOptions}
          minHeight="120vh"
          width="100%"
          m={5}
        />
      </OnDesktop>



      <div>
        {components?.map((comp, i) => (
          <div
            key={comp.id}
            ref={el => sectionsRef.current[i] = el}
          // style={{
          //   height: '100vh',
          //   backgroundColor: i % 2 === 0 ? 'lightcoral' : 'lightblue',
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          //   fontSize: '2rem',
          //   color: '#fff',
          // }}
          >
            {comp.component}
          </div>
        ))}
      </div>

      {/* <FlowChart id="flow_chart" />
      <Product id="product" />
      <OnMobile>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                  height="11rem" width="24.5rem"
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </OnMobile>
      <OnDesktop>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                  minHeight="75vh"
                  width="100%"
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </OnDesktop>
      <About id="aboutus" />
      <Service id="service" />
      <Client id="client" />
      <Partner id="partner" />
      <Testimonial id="testimonaial" />
      <News id="news" />
      <Event />
      <CaseStudiesVideo /> */}

      {/* footer */}
      < MKBox pt={5} px={0} mt={5} >
        <DefaultFooter content={footerRoutes} />
      </MKBox >
    </div>
  );
}

export default Website;
