import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";


export const generalSlice = createSlice({
    name: 'APIDATA',
    initialState: initialState,
    reducers: {
        setThemes: (state, action) => {
            console.log("action.payload",action.payload)
            return {
                ...state,
                themes: action.payload
            }
        },
    },
})


export const { setThemes } = generalSlice.actions;
export default generalSlice.reducer;