// @mui material components
// import Card from "@mui/material/Card";
import * as React from "react";

import ScrollToTop from "react-scroll-to-top";
// Material Kit 2 React components
import DefaultFooter from "components/Footer";
import DefaultNavbar from "components/Header";
import MKBox from "components/MKBox";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import colorValue from "assets/theme/base/colors";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Images
import bgImage from "assets/images/gifs/ai-revised2.gif";
import MKTypography from "components/MKTypography";

function Store_page() {
  console.log("bgImage ", bgImage);
  return (
    <div>
    <ScrollToTop smooth color="#000000" />
      {/* header */}
      < MKBox pt={5} px={0} mt={-5} >
      <DefaultNavbar
          routes={routes}
          sticky
      /></MKBox>
      {console.log(colorValue.headerColor)}
      <MKBox component="section"  my={15} py={2}id="store">
        <Container>
         
          <Grid container item xs={12} md={12} lg={12}>
            <Grid item xs={12} md={4} lg={4} justifyContent="center">
              <MKTypography
                variant="Overline"
                color="dark"
                textTransform="capitalize"
                fontWeight="light"
                // sx={({ breakpoints, typography: { size } }) => ({
                //     [breakpoints.down("md")]: {
                //         fontSize: size["xl"],
                //     },
                // })}
                id="store"
              >
                Eoxys
              </MKTypography>
              <MKTypography variant="h2" color="dark" textTransform="capitalize" fontWeight="bold">
                our Store
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={8} lg={8} justifyContent="center">
              <MKTypography variant="body1" color="dark" textAlign="justify" fontWeight="regular">
                <img
                  src="https://t3.ftcdn.net/jpg/04/85/63/38/360_F_485633827_gkgXcUfNmvjQGQ6PmEQ45WLAmu5jc3Oj.jpg"
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                  }}
                />
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={5} px={0} mt={5}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <ScrollToTop smooth color="#454545" backgroundColor="#000000" />
    </div>
  );
}

export default Store_page;
