import TextField from "@mui/material/TextField";
import MKTypography from "components/MKTypography";
import MenuItem from "@mui/material/MenuItem";

function Input({ id, labelValue, type, value, onChange, onBlur, errMessage, hasError, multiLine, maxRow, dropDown, dropDownList, defaultvalue, helpertext }) {
    let errMessage1 = "";
    let errClasses = "form-control";
    if (hasError) {
        errMessage1 =
            <>
                {errMessage}</>;
        errClasses = "form-control invalid";
    }

    return (
        <div className={errClasses}>
            {(multiLine != "") && (
                <TextField label={labelValue}
                    // color="#fff"
                    type={type || "text"}
                    id={id}
                    value={value}
                    multiline
                    maxRow={maxRow}
                    rows={3}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth="10rem"
                    mt={5} 
                    mb={3} 
                    sx={{color:'#000'}}
                    />
            )}
            {(multiLine == "") && (dropDown == "") && (
                <TextField label={labelValue}
                    type={type || "text"}
                    id={id}
                    mt={5} 
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth="10rem"
                    sx={{color:'#000'}}
                    mb={3} />
            )}
            {(multiLine != "") && (dropDown != "") && (
                <TextField
                    select
                    label={labelValue}
                    defaultValue={defaultvalue}
                    helperText={helpertext}
                    id={id}
                    mt={5} 
                    sx={{color:'#000'}}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth="10rem"
                    mb={3}
                >
                    {dropDownList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <br />
            <MKTypography
                variant="Caption"
                mt={2}
                mb={2}
                mx={1}
                fontWeight="medium"
                textTransform="capitalize"
                fontSize="0.8rem"
                color="primary"
            >
                {" "}
                {errMessage1}
            </MKTypography>
            <br />
            <br />
        </div>
    );
};

// Setting default props for the DefaultCounterCard
Input.defaultProps = {
    id: "", labelValue: "", type: "", value: "", onChange: "", onBlur: "", errMessage: "", hasError: "", multiLine: "", maxRow: 2, dropDown: "", dropDownList: {}, defaultvalue: "", helpertext: ""
};

// Typechecking props for the DefaultCounterCard
Input.propTypes = {
    id: "", labelValue: "", type: "", value: "", onChange: "", onBlur: "", errMessage: "", hasError: "", multiLine: "", maxRow: 2, dropDown: "", dropDownList: {}, defaultvalue: "", helpertext: ""
};
export default Input;
