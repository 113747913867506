import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Link, useLocation } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "components/Footer";
import DefaultNavbar from "components/Header";

// Routes
import footerRoutes from "footer.routes";
import { layoutGenerator } from 'react-break';
import ScrollToTop from "react-scroll-to-top";
import routes from "routes";

// Images
// import loraevkkitimg from "assets/images/products/EVKKit/lora_evk_kit.png";
import lteevkkitimg from "assets/images/products/EVKKit/lte_evk_kit.png";
// import nbiotevkkitimg from "assets/images/products/EVKKit/nbiot_evk_kit.png";
import wifievkkitimg from "assets/images/products/EVKKit/wifi_evk_kit.png";
import hamgrpimg from "assets/images/products/MLModel/hamgrp.png";
import humanalertmodelimg from "assets/images/products/MLModel/humanalertmodel.png";
import pestsensormodelimg from "assets/images/products/MLModel/pestsensormodel.png";
import psmgrpimg from "assets/images/products/MLModel/psmgrp.png";
// import lorasomimg from "assets/images/products/SOM/lorasom.png";
import ltesomimg from "assets/images/products/SOM/ltesom_update.png";
// import nbiotsomimg from "assets/images/products/SOM/nbiotsom.png";
import wifisomimg from "assets/images/products/SOM/lorasom_update.jpg";
import nbiotsomblockdiagramimg from "assets/images/products/SOM/BlockDiagram/lte_nbiot_blockdiagram.png";
import wifisomblockdiagramimg from "assets/images/products/SOM/BlockDiagram/wifi_ble_lora_blockdiagram.png";
import xsdkimg from "assets/images/products/SW/xsdk1.png";

import dbdevicespageimg from "assets/images/products/SW/CIOT/devices.png";
import dbloginpageimg from "assets/images/products/SW/CIOT/login.png";
import dborgpageimg from "assets/images/products/SW/CIOT/organization.png";
import dbplatinfopageimg from "assets/images/products/SW/CIOT/platinform.png";
import dbuserpageimg from "assets/images/products/SW/CIOT/user.png";
import dbdevicepageimg from "assets/images/products/SW/dbdevpg.png";
import dbhomepageimg from "assets/images/products/SW/dbhomepg.png";
// import dbdevicepageimg from "assets/images/products/SW/dbdevpg.png";

import dmsdevauthssimg from "assets/images/products/SW/devauth.png";
import dmsfotassimg from "assets/images/products/SW/fotadownload.png";
import dmstcpssimg from "assets/images/products/SW/tcpsection.png";

//PDF   
// import wifisom from "assets/files/Brochure/XENO+ WiFi+BLE-ML-SOM-BROCHURE-HR.pdf";
// import nbiotsom from "assets/files/Brochure/XENO-NBIOT-ML-SOM-BROCHURE.pdf";

//Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from "@material-ui/core/styles";

const layout = layoutGenerator({
    mobile: 0,
    desktop: 992,
});
const useStyles = makeStyles({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            border: "1px solid rgba(158, 158, 158, 0.5)"
        }
    }
});


const OnMobile = layout.is('mobile');
const OnDesktop = layout.is('desktop');

function Product_Page() {

    const classes = useStyles();
    // const columns = [
    //     {
    //         id: 'no', label: 'No', minWidth: 75,
    //         align: 'left',
    //     },
    //     {
    //         id: 'features', label: 'Features', minWidth: 300,
    //         align: 'left',
    //     },
    //     {
    //         id: 'specifications',
    //         label: 'Specifications',
    //         minWidth: 170,
    //         align: 'left',
    //     },
    // ];
    const wifi_ble_som_columns = [
        {
            id: 'no', label: 'No', minWidth: 75,
            align: 'center',
        },
        {
            id: 'ml', label: 'XENO+ ML SOM', minWidth: 100,
            align: 'center',
        },
        {
            id: 'wifible_ml',
            label: 'WiFi+BLE ML SOM',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lora_ml',
            label: 'LoRa ML SOM',
            minWidth: 150,
            align: 'center',
        },
    ];
    const nbiot_lora_som_columns = [
        {
            id: 'no', label: 'No', minWidth: 75,
            align: 'center',
        },
        {
            id: 'ml', label: 'XENO+ ML SOM', minWidth: 100,
            align: 'center',
        },
        {
            id: 'lte_cat1',
            label: 'LTE Cat-1 ML SOM',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lte_cat1_bis',
            label: 'LTE Cat-1.bis ML SOM',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lte_cat_m',
            label: 'LTE Cat-M ML SOM',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lte_nbiot_ml',
            label: 'LTE NB-IoT ML SOM',
            minWidth: 150,
            align: 'center',
        },
    ];
    const wifi_ble_evk_columns = [
        {
            id: 'no', label: 'No', minWidth: 75,
            align: 'center',
        },
        {
            id: 'ml', label: 'XENO+ ML EVK', minWidth: 100,
            align: 'center',
        },
        {
            id: 'wifible_ml',
            label: 'WiFi+BLE ML EVK',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lora_ml',
            label: 'LoRa ML EVK',
            minWidth: 150,
            align: 'center',
        },
    ];
    const nbiot_lora_evk_columns = [
        {
            id: 'no', label: 'No', minWidth: 75,
            align: 'center',
        },
        {
            id: 'ml', label: 'XENO+ ML EVK', minWidth: 100,
            align: 'center',
        },
        {
            id: 'lte_cat1',
            label: 'LTE Cat-1 ML EVK',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lte_cat1_bis',
            label: 'LTE Cat-1.bis ML EVK',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lte_cat_m',
            label: 'LTE Cat-M ML EVK',
            minWidth: 150,
            align: 'center',
        },
        {
            id: 'lte_nbiot_ml',
            label: 'LTE NB-IoT ML EVK',
            minWidth: 150,
            align: 'center',
        },
    ];
    // function createData(no, features, specifications) {
    //     return { no, features, specifications };
    // }
    function createDataWifiBLE(no, ml, wifible_ml, lora_ml) {
        return { no, ml, wifible_ml, lora_ml };
    }
    function createDataLoRaNBIoT(no, ml, lte_cat1, lte_cat1_bis, lte_cat_m, lte_nbiot_ml) {
        return { no, ml, lte_cat1, lte_cat1_bis, lte_cat_m, lte_nbiot_ml };
    }




    var location = useLocation();
    var pathname = location.pathname.split("/")
    var productpage = pathname[pathname.length - 1]
    const wifisomdetails = window.wifiblesom;
    const nbiotmlsomdetails = window.nbiotmlsom;
    const wifiEVKKitDetails = window.wifievkkit;
    // const loraEVKKitDetails = window.loraevkkit;
    // const lteEVKKitDetails = window.lteevkkit;
    const nbiotEVKKitDetails = window.nbiotevkkit;
    const humanAlertModeldetails = window.humanAlertModel;
    const pestSensorModeldetails = window.pestSensorModel;
    const devicemanageserverdetails = window.devicemanageserver;
    const customizableIOTDBdetails = window.customizableIOTDB;//XENOSDK
    const XENOSDKdetails = window.XENOSDK;

    const wifiblesomrows = wifisomdetails.funspec.map((columndata) => {
        console.log("columndata", columndata)
        return (createDataWifiBLE(columndata.no, columndata.ml, columndata.wifible_ml, columndata.lora_ml));
    });
    const nbiotmlsomrows = nbiotmlsomdetails.funspec.map((columndata) => {
        return (createDataLoRaNBIoT(columndata.no, columndata.ml, columndata.lte_cat1, columndata.lte_cat1_bis, columndata.lte_cat_m, columndata.lte_nbiot_ml));
    });
    const wifievkkitrows = wifiEVKKitDetails.funspec.map((columndata) => {
        return (createDataWifiBLE(columndata.no, columndata.ml, columndata.wifible_ml, columndata.lora_ml));
    });
    // const loraevkkitrows = loraEVKKitDetails.funspec.map((columndata) => {
    //     return (createData(columndata.no, columndata.features, columndata.specifications));
    // });
    // const lteevkkitrows = lteEVKKitDetails.funspec.map((columndata) => {
    //     return (createData(columndata.no, columndata.features, columndata.specifications));
    // });
    const nbiotevkkitrows = nbiotEVKKitDetails.funspec.map((columndata) => {
        return (createDataLoRaNBIoT(columndata.no, columndata.ml, columndata.lte_cat1, columndata.lte_cat1_bis, columndata.lte_cat_m, columndata.lte_nbiot_ml));
    });
    return (
        <div>
            <ScrollToTop smooth color="#000000" />
            {/* header */}
            < MKBox pt={5} px={0} mt={-5} >
                <DefaultNavbar
                    routes={routes}
                    sticky
                /></MKBox>

            <MKBox component="section" my={15} py={2}>

                {(productpage == "nbiotsom") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="center">
                            <Grid item xs={12} md={6} lg={6} marginTop={10} justifyContent="center" >
                                <OnMobile>
                                    <MKBox component="img" src={ltesomimg} alt="NBIOT IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={ltesomimg} alt="NBIOT IMAGE" height="auto" width="95%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} justifyContent="center">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                >  {nbiotmlsomdetails.productname} </MKTypography>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {nbiotmlsomdetails.overview}
                                </MKTypography>
                            </Grid>
                        </Grid>
                        {(nbiotmlsomdetails.additionaloverview) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {nbiotmlsomdetails.additionaloverview}</MKTypography>
                            </Grid>
                        )}
                        {(nbiotmlsomdetails.additionaloverview1) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="bold" lineHeight={2} >
                                    {nbiotmlsomdetails.additionaloverview1}</MKTypography>
                            </Grid>
                        )}
                        {(nbiotmlsomdetails.additionaloverview1list) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                <ul>
                                    {nbiotmlsomdetails.additionaloverview1list.map((column) => {
                                        return (
                                            <>
                                                <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    {column}
                                                </MKTypography></li>
                                            </>
                                        );
                                    })}</ul>
                            </Grid>
                        )}
                        {(nbiotmlsomdetails.additionaloverview2) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {nbiotmlsomdetails.additionaloverview2}</MKTypography>
                            </Grid>
                        )}
                        {(nbiotmlsomdetails.additionaloverview3) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {nbiotmlsomdetails.additionaloverview3}</MKTypography>
                            </Grid>
                        )}

                        {/* <Grid container item xs={12} md={12} lg={12} justifyContent="center" p={2} my={2}>
                            <Grid item xs={12} md={12} lg={12} px={2}>
                                <OnMobile>
                                    <MKBox component="img" src={nbiotsomblockdiagramimg} alt="NBIOT IMAGE" height="30vh" width="100%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with LTE Cellular Connectivity</MKTypography>
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={nbiotsomblockdiagramimg} alt="NBIOT IMAGE" height="50%" width="50%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with LTE Cellular Connectivity</MKTypography>
                                </OnDesktop>
                            </Grid>
                        </Grid> */}

                        <Grid container item xs={12} md={12} lg={12} justifyContent="start" mt={3}>
                            {/* <Grid item xs={12} md={6} lg={6} justifyContent="center" mt={3} mb={1} >
                                <MKTypography variant="h4"
                                    fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                    lineHeight={2}>
                                    Documentation
                                </MKTypography>
                                <MKTypography variant="h6"
                                    fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                    lineHeight={2}>
                                    1. Product Brochure &nbsp;
                                    <MKTypography
                                        component="a"
                                        href={nbiotsom}
                                        target="_blank"
                                        rel="noreferrer"
                                        variant="button"
                                        fontWeight="regular"
                                        color="headerColor"
                                        textTransform="capitalize"
                                    >click here</MKTypography> &nbsp;
                                    to view.
                                </MKTypography>
                                <MKTypography variant="h6"
                                    fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                    lineHeight={2}>
                                    2. Product Datasheet &nbsp;
                                    <MKTypography
                                        component={Link}
                                        to="/website#contactus"
                                        variant="button"
                                        fontWeight="regular"
                                        color="headerColor"
                                        textTransform="capitalize"
                                    > click here &nbsp;
                                    </MKTypography> to contact us.
                                </MKTypography>
                            </Grid> */}
                            <Grid item xs={12} md={6} lg={6} px={2}>
                                <OnMobile>
                                    <MKBox component="img" src={nbiotsomblockdiagramimg} alt="NBIOT IMAGE" height="auto" width="100%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with LTE Cellular Connectivity.<br />Datasheet &nbsp;
                                        <MKTypography
                                            component={Link}
                                            to="/website#contactus"
                                            variant="button"
                                            fontWeight="regular"
                                            color="headerColor"
                                            textTransform="capitalize"
                                        > click here &nbsp;
                                        </MKTypography> to contact us.</MKTypography>
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={nbiotsomblockdiagramimg} alt="NBIOT IMAGE" height="auto" width="100%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with LTE Cellular Connectivity.<br />Datasheet &nbsp;
                                        <MKTypography
                                            component={Link}
                                            to="/website#contactus"
                                            variant="button"
                                            fontWeight="regular"
                                            color="headerColor"
                                            textTransform="capitalize"
                                        > click here &nbsp;
                                        </MKTypography> to contact us.</MKTypography>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3} px={2}>
                                <OnMobile>
                                    <iframe width="300" height="280"
                                        src={nbiotmlsomdetails.youtube}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnMobile>
                                <OnDesktop>
                                    <iframe width="500" height="300"
                                        src={nbiotmlsomdetails.youtube}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnDesktop>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} md={12} lg={12} justifyContent="center" mt={3} mb={1} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                Product Features and Specifications
                            </MKTypography>
                            <TableContainer >
                                <Table stickyHeader aria-label="sticky table" className={classes.table} >
                                    <TableBody>
                                        <TableRow sx={{ background: "#9ec0cf" }}>
                                            {nbiot_lora_som_columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: "bold", color: "#000000" }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                    <TableBody >
                                        {nbiotmlsomrows.map(({ no, ml, lte_cat1, lte_cat1_bis, lte_cat_m, lte_nbiot_ml }) => (

                                            <>
                                                {(no !== "") && (ml !== null) && (lte_cat1 !== "") && (lte_cat1_bis !== "") && (lte_cat_m !== null) && (lte_nbiot_ml !== "") && (

                                                    <TableRow key={no} style={{ background: "#ebeced" }}>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} >{no}</TableCell>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{ml}</TableCell>
                                                        {(lte_cat1_bis == "") ? (
                                                            <>
                                                                <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} colSpan={4}>{lte_cat1}</TableCell>
                                                            </>) : <>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_cat1}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_cat1_bis}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_cat_m}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_nbiot_ml}</TableCell>
                                                        </>}
                                                    </TableRow>
                                                )}

                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Container>
                )}
                {(productpage == "wifisom") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="center">
                            <Grid item xs={12} md={6} lg={6} mt={10} justifyContent="center" >
                                <OnMobile>
                                    <MKBox component="img" src={wifisomimg} alt="Wifi IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={wifisomimg} alt="Wifi IMAGE" height="auto" width="95%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} justifyContent="center">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                >  {wifisomdetails.productname} </MKTypography>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {wifisomdetails.overview}
                                </MKTypography>
                            </Grid>
                        </Grid>
                        {(wifisomdetails.additionaloverview) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {wifisomdetails.additionaloverview}</MKTypography>
                            </Grid>
                        )}
                        {(wifisomdetails.additionaloverview1) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="bold" lineHeight={2} >
                                    {wifisomdetails.additionaloverview1}</MKTypography>
                            </Grid>
                        )}
                        {(wifisomdetails.additionaloverview1list) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                <ul>
                                    {wifisomdetails.additionaloverview1list.map((column) => {
                                        return (
                                            <>
                                                <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    {column}
                                                </MKTypography></li>
                                            </>
                                        );
                                    })}</ul>
                            </Grid>
                        )}
                        {(wifisomdetails.additionaloverview2) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {wifisomdetails.additionaloverview2}</MKTypography>
                            </Grid>
                        )}
                        {(wifisomdetails.additionaloverview3) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {wifisomdetails.additionaloverview3}</MKTypography>
                            </Grid>
                        )}

                        {/* <Grid container item xs={12} md={12} lg={12} justifyContent="center">
                            <Grid item xs={12} md={6} lg={6} px={2}>
                                <OnMobile>
                                    <MKBox component="img" src={wifisomblockdiagramimg} alt="NBIOT IMAGE" height="30vh" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={wifisomblockdiagramimg} alt="NBIOT IMAGE" height="47.5vh" width="95%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} px={2}>
                                <OnMobile>
                                    <iframe width="300" height="280"
                                        src={wifisomdetails.youtube}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnMobile>
                                <OnDesktop>
                                    <iframe width="350" height="280"
                                        src={wifisomdetails.youtube}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnDesktop>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} justifyContent="center" mt={3} mb={1} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                Documentation
                            </MKTypography>
                            <MKTypography variant="h6"
                                fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                1. Product Brochure &nbsp;
                                <MKTypography
                                    component="a"
                                    href={wifisom}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="button"
                                    fontWeight="regular"
                                    color="headerColor"
                                    textTransform="capitalize"
                                >click here</MKTypography> &nbsp;
                                to view.
                            </MKTypography>
                            <MKTypography variant="h6"
                                fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                2. Product Datasheet  &nbsp;
                                <MKTypography
                                    component={Link}
                                    to="/website#contactus"
                                    variant="button"
                                    fontWeight="regular"
                                    color="headerColor"
                                    textTransform="capitalize"
                                > click here &nbsp;
                                </MKTypography>  to contact us.
                            </MKTypography>
                        </Grid> */}

                        {/* <Grid container item xs={12} md={12} lg={12} justifyContent="center" p={2} my={2}>
                            <Grid item xs={12} md={12} lg={12} p={2} justifyContent="center">
                                <OnMobile>
                                    <MKBox component="img" src={wifisomblockdiagramimg} alt="NBIOT IMAGE" height="30vh" width="100%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with WiFi+BLE and LoRa Connectivity</MKTypography>
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={wifisomblockdiagramimg} alt="NBIOT IMAGE" height="50%" width="50%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with WiFi+BLE and LoRa Connectivity</MKTypography>
                                </OnDesktop>
                            </Grid>
                        </Grid> */}

                        <Grid container item xs={12} md={12} lg={12} justifyContent="start" mt={3}>
                            {/* <Grid item xs={12} md={6} lg={6} justifyContent="center" mt={3} mb={1} >
                                <MKTypography variant="h4"
                                    fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                    lineHeight={2}>
                                    Documentation
                                </MKTypography>
                                <MKTypography variant="h6"
                                    fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                    lineHeight={2}>
                                    1. Product Brochure &nbsp;
                                    <MKTypography
                                        component="a"
                                        href={wifisom}
                                        target="_blank"
                                        rel="noreferrer"
                                        variant="button"
                                        fontWeight="regular"
                                        color="headerColor"
                                        textTransform="capitalize"
                                    >click here</MKTypography> &nbsp;
                                    to view.
                                </MKTypography>
                                <MKTypography variant="h6"
                                    fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                    lineHeight={2}>
                                    2. Product Datasheet &nbsp;
                                    <MKTypography
                                        component={Link}
                                        to="/website#contactus"
                                        variant="button"
                                        fontWeight="regular"
                                        color="headerColor"
                                        textTransform="capitalize"
                                    > click here &nbsp;
                                    </MKTypography> to contact us.
                                </MKTypography>
                            </Grid> */}
                            <Grid item xs={12} md={6} lg={6} px={2} justifyContent="start">
                                <OnMobile>
                                    <MKBox component="img" src={wifisomblockdiagramimg} alt="NBIOT IMAGE" height="auto" width="100%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with WiFi+BLE and LoRa Connectivity.<br />Datasheet &nbsp;
                                        <MKTypography
                                            component={Link}
                                            to="/website#contactus"
                                            variant="button"
                                            fontWeight="regular"
                                            color="headerColor"
                                            textTransform="capitalize"
                                        > click here &nbsp;
                                        </MKTypography> to contact us.</MKTypography>
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={wifisomblockdiagramimg} alt="NBIOT IMAGE" height="auto" width="100%" borderRadius="xl" />
                                    <MKTypography variant="h6" p={2} justifyContent="start">Block diagram of XENO+ ML SOM with WiFi+BLE and LoRa Connectivity.<br />Datasheet &nbsp;
                                        <MKTypography
                                            component={Link}
                                            to="/website#contactus"
                                            variant="button"
                                            fontWeight="regular"
                                            color="headerColor"
                                            textTransform="capitalize"
                                        > click here &nbsp;
                                        </MKTypography> to contact us.</MKTypography>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3} px={2}>
                                <OnMobile>
                                    <iframe width="300" height="280"
                                        src={wifisomdetails.youtube}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnMobile>
                                <OnDesktop>
                                    <iframe width="500" height="300"
                                        src={wifisomdetails.youtube}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnDesktop>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} justifyContent="center" my={3} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                Product Features and Specifications
                            </MKTypography>

                            <TableContainer >
                                <Table stickyHeader aria-label="sticky table" className={classes.table} >
                                    <TableBody>
                                        <TableRow sx={{ background: "#9ec0cf" }}>
                                            {wifi_ble_som_columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: "bold", color: "#000000" }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                    <TableBody >
                                        {wifiblesomrows.map(({ no, ml, wifible_ml, lora_ml }) => (
                                            <>
                                                {(no !== "") && (ml !== null) && (wifible_ml !== "") && (lora_ml !== "") && (

                                                    <TableRow key={no} style={{ background: "#ebeced" }}>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} >{no}</TableCell>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{ml}</TableCell>

                                                        {(lora_ml == "") ? (
                                                            <>
                                                                <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} colSpan={2}>{wifible_ml}</TableCell>
                                                            </>) : <>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{wifible_ml}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lora_ml}</TableCell>
                                                        </>}

                                                    </TableRow>
                                                )}
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Container>
                )}

                {(productpage == "wifievkkit") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="center">
                            <Grid item xs={12} md={6} lg={6} mt={7} textAlign="center" justifyContent="center" >
                                <OnMobile>
                                    <MKBox component="img" src={wifievkkitimg} alt="Wifi EVK Kit IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={wifievkkitimg} alt="Wifi EVK Kit IMAGE" height="auto" width="60%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} justifyContent="center">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                >  {wifiEVKKitDetails.productname} </MKTypography>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {wifiEVKKitDetails.overview}
                                    {(wifiEVKKitDetails.additionaloverview) && (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                            <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                fontWeight="regular" lineHeight={2} >
                                                {wifiEVKKitDetails.additionaloverview}</MKTypography>
                                        </Grid>
                                    )}
                                    {(wifiEVKKitDetails.additionaloverviewlist) && (

                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {wifiEVKKitDetails.additionaloverviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })}</ul>
                                        </Grid>
                                    )}
                                </MKTypography>
                            </Grid>
                        </Grid>
                        {(wifiEVKKitDetails.additionaloverview1) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {wifiEVKKitDetails.additionaloverview1}</MKTypography>
                            </Grid>
                        )}
                        {(wifiEVKKitDetails.additionaloverview1list) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                <ul>
                                    {wifiEVKKitDetails.additionaloverview1list.map((column) => {
                                        return (
                                            <>
                                                <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    {column}
                                                </MKTypography></li>
                                            </>
                                        );
                                    })}</ul>
                            </Grid>
                        )}
                        {(wifiEVKKitDetails.additionaloverview2) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="bold" lineHeight={2} >
                                    {wifiEVKKitDetails.additionaloverview2}</MKTypography>
                            </Grid>
                        )}
                        {(wifiEVKKitDetails.additionaloverview2list) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                <ul>
                                    {wifiEVKKitDetails.additionaloverview2list.map((column) => {
                                        return (
                                            <>
                                                <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    {column}
                                                </MKTypography></li>
                                            </>
                                        );
                                    })}</ul>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12} justifyContent="center" mt={3} mb={1} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                Documentation
                            </MKTypography>
                            {/* <MKTypography variant="h6"
                                fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                1. Product Brochure &nbsp;
                                <MKTypography
                                    component="a"
                                    href={wifisom}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="button"
                                    fontWeight="regular"
                                    color="headerColor"
                                    textTransform="capitalize"
                                >click here</MKTypography> &nbsp;
                                to view.
                            </MKTypography> */}
                            <MKTypography variant="h6"
                                fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                1. Product Datasheet  &nbsp;
                                <MKTypography
                                    component={Link}
                                    to="/website#contactus"
                                    variant="button"
                                    fontWeight="regular"
                                    color="headerColor"
                                    textTransform="capitalize"
                                > click here &nbsp;
                                </MKTypography> to contact us.
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={3} mb={1} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                Product Features and Specifications
                            </MKTypography>
                            <TableContainer >
                                <Table stickyHeader aria-label="sticky table" className={classes.table} >
                                    <TableBody>
                                        <TableRow sx={{ background: "#9ec0cf" }}>
                                            {wifi_ble_evk_columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: "bold", color: "#000000" }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                    <TableBody >
                                        {wifievkkitrows.map(({ no, ml, wifible_ml, lora_ml }) => (
                                            <>
                                                {(no !== "") && (ml !== null) && (wifible_ml !== "") && (lora_ml !== "") && (

                                                    <TableRow key={no} style={{ background: "#ebeced" }}>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} >{no}</TableCell>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{ml}</TableCell>

                                                        {(lora_ml == "") ? (
                                                            <>
                                                                <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} colSpan={2}>{wifible_ml}</TableCell>
                                                            </>) : <>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{wifible_ml}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lora_ml}</TableCell>
                                                        </>}

                                                    </TableRow>
                                                )}
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Container>
                )}
                {(productpage == "nbiotevkkit") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="center">
                            <Grid item xs={12} md={6} lg={6} mt={10} textAlign="center" justifyContent="center" >
                                <OnMobile>
                                    <MKBox component="img" src={lteevkkitimg} alt="Wifi EVK Kit IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={lteevkkitimg} alt="Wifi EVK Kit IMAGE" height="auto" width="60%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} justifyContent="center">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                >  {nbiotEVKKitDetails.productname} </MKTypography>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {nbiotEVKKitDetails.overview}
                                    {(nbiotEVKKitDetails.additionaloverview) && (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                            <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                fontWeight="regular" lineHeight={2} >
                                                {nbiotEVKKitDetails.additionaloverview}</MKTypography>
                                        </Grid>
                                    )}
                                    {(nbiotEVKKitDetails.additionaloverviewlist) && (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {nbiotEVKKitDetails.additionaloverviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })}</ul>
                                        </Grid>
                                    )}
                                </MKTypography>
                            </Grid>
                        </Grid>
                        {(nbiotEVKKitDetails.additionaloverview1) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="regular" lineHeight={2} >
                                    {nbiotEVKKitDetails.additionaloverview1}</MKTypography>
                            </Grid>
                        )}
                        {(nbiotEVKKitDetails.additionaloverview1list) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                <ul>
                                    {nbiotEVKKitDetails.additionaloverview1list.map((column) => {
                                        return (
                                            <>
                                                <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    {column}
                                                </MKTypography></li>
                                            </>
                                        );
                                    })}</ul>
                            </Grid>
                        )}
                        {(nbiotEVKKitDetails.additionaloverview2) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                    fontWeight="bold" lineHeight={2} >
                                    {nbiotEVKKitDetails.additionaloverview2}</MKTypography>
                            </Grid>
                        )}
                        {(nbiotEVKKitDetails.additionaloverview2list) && (
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                <ul>
                                    {nbiotEVKKitDetails.additionaloverview2list.map((column) => {
                                        return (
                                            <>
                                                <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    {column}
                                                </MKTypography></li>
                                            </>
                                        );
                                    })}</ul>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12} justifyContent="center" mt={3} mb={1} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                Documentation
                            </MKTypography>
                            {/* <MKTypography variant="h6"
                                fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                1. Product Brochure &nbsp;
                                <MKTypography
                                    component="a"
                                    href={wifisom}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="button"
                                    fontWeight="regular"
                                    color="headerColor"
                                    textTransform="capitalize"
                                >click here</MKTypography> &nbsp;
                                to view.
                            </MKTypography> */}
                            <MKTypography variant="h6"
                                fontWeight="medium" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                1. Product Datasheet  &nbsp;
                                <MKTypography
                                    component={Link}
                                    to="/website#contactus"
                                    variant="button"
                                    fontWeight="regular"
                                    color="headerColor"
                                    textTransform="capitalize"
                                > click here &nbsp;
                                </MKTypography> to contact us.
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={3} mb={1} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark" p={2}
                                lineHeight={2}>
                                Product Features and Specifications
                            </MKTypography>
                            <TableContainer >
                                <Table stickyHeader aria-label="sticky table" className={classes.table} >
                                    <TableBody>
                                        <TableRow sx={{ background: "#9ec0cf" }}>
                                            {nbiot_lora_evk_columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight: "bold", color: "#000000" }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                    <TableBody >
                                        {nbiotevkkitrows.map(({ no, ml, lte_cat1, lte_cat1_bis, lte_cat_m, lte_nbiot_ml }) => (

                                            <>
                                                {(no !== "") && (ml !== null) && (lte_cat1 !== "") && (lte_cat1_bis !== "") && (lte_cat_m !== null) && (lte_nbiot_ml !== "") && (

                                                    <TableRow key={no} style={{ background: "#ebeced" }}>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} >{no}</TableCell>
                                                        <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{ml}</TableCell>
                                                        {(lte_cat1_bis == "") ? (
                                                            <>
                                                                <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }} colSpan={4}>{lte_cat1}</TableCell>
                                                            </>) : <>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_cat1}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_cat1_bis}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_cat_m}</TableCell>
                                                            <TableCell sx={{ color: "#000000", fontWeight: "medium", fontSize: "xl" }}>{lte_nbiot_ml}</TableCell>
                                                        </>}
                                                    </TableRow>
                                                )}

                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Container>
                )}


                {(productpage == "hasm") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify">
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <OnMobile>
                                    <MKBox component="img" src={humanalertmodelimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={humanalertmodelimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                > {humanAlertModeldetails.productname} </MKTypography>
                                <ul>
                                    {(humanAlertModeldetails.overview) && (

                                        humanAlertModeldetails.overview.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li>
                                                </>
                                            );
                                        })
                                    )
                                    }
                                </ul>
                                {(humanAlertModeldetails.overviewpara) && (
                                    <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                        fontWeight="regular" lineHeight={2}>
                                        {humanAlertModeldetails.overviewpara}
                                    </MKTypography>
                                )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} justifyContent="center" my={3} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark"
                                lineHeight={2}>
                                Pre Trained Model
                            </MKTypography>

                            <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                fontWeight="regular" lineHeight={2}>
                                {humanAlertModeldetails.perTrainedModelOV}
                            </MKTypography>
                        </Grid>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" ml={5} >

                            <Grid item xs={12} md={6} lg={6} justifyContent="justify" >
                                <ul>
                                    {(humanAlertModeldetails.perTrainedModel) && (
                                        humanAlertModeldetails.perTrainedModel.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li>
                                                </>
                                            );
                                        })
                                    )
                                    }
                                </ul>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} justifyContent="justify" >
                                <OnMobile>
                                    <MKBox component="img" src={hamgrpimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={hamgrpimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnDesktop>
                                {/* <MKBox component="img" src={hamgrpimg} alt="About US" maxWidth="30rem" height="30rem" borderRadius="xl" /> */}
                            </Grid>
                        </Grid>
                    </Container>
                )}
                {(productpage == "psm") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify">
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <OnMobile>
                                    <MKBox component="img" src={pestsensormodelimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={pestsensormodelimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                > {pestSensorModeldetails.productname} </MKTypography>
                                {/* {(pestSensorModeldetails.overview) && (
                                    pestSensorModeldetails.overview.map((column) => {
                                        return (
                                            <>
                                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon> &nbsp; {column}
                                                </MKTypography></>
                                        );
                                    })
                                )} */}
                                <ul>
                                    {(pestSensorModeldetails.overview) && (

                                        pestSensorModeldetails.overview.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li>
                                                </>
                                            );
                                        })
                                    )
                                    }

                                </ul>
                                {(pestSensorModeldetails.overviewpara) && (
                                    <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                        fontWeight="regular" lineHeight={2}>
                                        {pestSensorModeldetails.overviewpara}
                                    </MKTypography>
                                )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} justifyContent="center" my={3} >
                            <MKTypography variant="h4"
                                fontWeight="bold" textAlign="justify" m="auto" color="dark"
                                lineHeight={2}>
                                Pre Trained Model
                            </MKTypography>

                            <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                fontWeight="regular" lineHeight={2}>
                                {pestSensorModeldetails.perTrainedModelOV}
                            </MKTypography>
                        </Grid>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" ml={5} >
                            <Grid item xs={12} md={6} lg={6} justifyContent="justify" >
                                <ul>
                                    {(pestSensorModeldetails.perTrainedModel) && (

                                        pestSensorModeldetails.perTrainedModel.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li>
                                                </>
                                            );
                                        })
                                    )
                                    }

                                </ul>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} justifyContent="justify" >
                                <OnMobile>
                                    <MKBox component="img" src={psmgrpimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={psmgrpimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={6} lg={6} justifyContent="justify" >
                                <MKBox component="img" src={psmgrpimg} alt="About US" maxWidth="20rem" height="20rem" borderRadius="xl" />
                            </Grid> */}
                        </Grid>
                    </Container>
                )}
                {(productpage == "vsm") && (
                    <Container justifyContent="center">

                        <Grid container item xs={12} md={12} lg={12}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="center">
                                <MKTypography variant="h2" color="dark" textAlign="center" textTransform="capitalize" justifyContent="center" fontWeight="bold">
                                    Vibration Sensor Models
                                </MKTypography>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12} justifyContent="center" p={2} >
                                <OnMobile>
                                    <MKTypography variant="body1" color="dark" textAlign="center" fontWeight="regular">
                                        <img
                                            src="https://t3.ftcdn.net/jpg/04/85/63/38/360_F_485633827_gkgXcUfNmvjQGQ6PmEQ45WLAmu5jc3Oj.jpg"
                                            style={{
                                                alignItems: "center",
                                                alignContent: "center",
                                                height: "24vh", width: "100%"
                                            }}
                                        />
                                    </MKTypography>
                                </OnMobile>
                                <OnDesktop>
                                    <MKTypography variant="body1" color="dark" textAlign="center" fontWeight="regular">
                                        <img
                                            src="https://t3.ftcdn.net/jpg/04/85/63/38/360_F_485633827_gkgXcUfNmvjQGQ6PmEQ45WLAmu5jc3Oj.jpg"
                                            style={{
                                                alignItems: "center",
                                                alignContent: "center",
                                            }}
                                        />
                                    </MKTypography>
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="center" p={2}>
                                <MKTypography variant="body1" color="dark" textAlign="center" fontWeight="regular">
                                    <img
                                        src="https://t3.ftcdn.net/jpg/04/85/63/38/360_F_485633827_gkgXcUfNmvjQGQ6PmEQ45WLAmu5jc3Oj.jpg"
                                        style={{
                                            alignItems: "center",
                                            alignContent: "center",
                                        }}
                                    />
                                </MKTypography>
                            </Grid> */}
                        </Grid>
                    </Container>
                )}


                {(productpage == "xsdk") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify">
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={xsdkimg} alt="About US" maxWidth="100rem" height="50rem" borderRadius="xl" />
                            </Grid> */}

                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <OnMobile>
                                    <MKBox component="img" src={xsdkimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={xsdkimg} alt="Wifi RTU IMAGE" height="auto" width="100%" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                > {XENOSDKdetails.productname} </MKTypography>
                                <ul>
                                    {(XENOSDKdetails.overview) && (
                                        XENOSDKdetails.overview.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li>
                                                </>
                                            );
                                        })
                                    )
                                    }

                                </ul>
                                {(XENOSDKdetails.overviewpara) && (
                                    <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                        fontWeight="regular" lineHeight={2}>
                                        {XENOSDKdetails.overviewpara}
                                    </MKTypography>
                                )
                                }
                                {(XENOSDKdetails.overview1title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview1title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview1) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview1}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview1list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(
                                                XENOSDKdetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })
                                            )
                                            }

                                        </ul>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview2title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview2title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview2) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview2}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview2list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(
                                                XENOSDKdetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })
                                            )
                                            }

                                        </ul>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview3title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview3title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview3) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview3}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview3list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(
                                                XENOSDKdetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })
                                            )
                                            }

                                        </ul>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview4title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview4title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview4) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview4}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview4list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(
                                                XENOSDKdetails.overview4list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })
                                            )
                                            }

                                        </ul>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview5title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview5title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview5) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview5}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview5list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(
                                                XENOSDKdetails.overview5list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })
                                            )
                                            }

                                        </ul>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview6title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview6title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview6) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview6}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview6list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(
                                                XENOSDKdetails.overview6list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })
                                            )
                                            }

                                        </ul>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview7title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview7title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview7) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview7}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview7list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(XENOSDKdetails.overview7list.map((column) => {
                                                return (
                                                    <>
                                                        <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                            fontWeight="regular" lineHeight={2} key={column}>
                                                            {column}
                                                        </MKTypography></li>
                                                    </>
                                                );
                                            }))}

                                        </ul>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview8title) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="bold" lineHeight={2} >
                                            {XENOSDKdetails.overview8title}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview8) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                        <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                            fontWeight="regular" lineHeight={2} >
                                            {XENOSDKdetails.overview8}</MKTypography>
                                    </Grid>
                                )}
                                {(XENOSDKdetails.overview8list) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {(
                                                XENOSDKdetails.overview8list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                                fontWeight="regular" lineHeight={2} key={column}>
                                                                {column}
                                                            </MKTypography></li>
                                                        </>
                                                    );
                                                })
                                            )
                                            }

                                        </ul>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                )}
                {(productpage == "dms") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify">
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                > {devicemanageserverdetails.productname} </MKTypography>
                                {(devicemanageserverdetails.overview) && (
                                    <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                        fontWeight="regular" lineHeight={2}>
                                        {devicemanageserverdetails.overview}
                                    </MKTypography>
                                )
                                }
                                <ul>
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        {(devicemanageserverdetails.overviewlist) && (
                                            devicemanageserverdetails.overviewlist.map((column) => {
                                                return (
                                                    <>
                                                        <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                            fontWeight="regular" lineHeight={2} key={column}>
                                                            {column}
                                                        </MKTypography></li>
                                                    </>
                                                );
                                            })
                                        )
                                        }
                                    </Grid>
                                </ul>
                                {/* {(devicemanageserverdetails.overviewlist) && (
                                    devicemanageserverdetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                    fontWeight="regular" lineHeight={2} key={column}>
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon> &nbsp; {column}
                                                </MKTypography></>
                                        );
                                    })
                                )
                                } */}
                            </Grid>
                            {(devicemanageserverdetails.overview1) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {devicemanageserverdetails.overview1}</MKTypography>
                                </Grid>
                            )}
                            {(devicemanageserverdetails.overview1list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {devicemanageserverdetails.overview1list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2} >
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                    <OnMobile>
                                        <MKBox component="img" src={dmstcpssimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                    </OnMobile>
                                    <OnDesktop>
                                        <MKBox component="img" src={dmstcpssimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                    </OnDesktop>
                                </Grid>

                            </Grid>
                            {(devicemanageserverdetails.overview2) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {devicemanageserverdetails.overview2}</MKTypography>
                                </Grid>
                            )}
                            {(devicemanageserverdetails.overview1list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {devicemanageserverdetails.overview1list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            {/* dmsdevauthssimg */}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2}>
                                <OnMobile>
                                    <MKBox component="img" src={dmsdevauthssimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dmsdevauthssimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dmsdevauthssimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                            </Grid> */}
                            {(devicemanageserverdetails.overview3) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={3} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {devicemanageserverdetails.overview3}</MKTypography>
                                </Grid>
                            )}
                            {(devicemanageserverdetails.overview3list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {devicemanageserverdetails.overview3list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2}>
                                <OnMobile>
                                    <MKBox component="img" src={dmsfotassimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dmsfotassimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dmsfotassimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                            </Grid> */}
                        </Grid>
                    </Container>
                )}
                {(productpage == "ciotdb") && (
                    <Container>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify">
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold" textAlign="center"
                                    color="dark"
                                    textTransform="capitalize" my={2}
                                > {customizableIOTDBdetails.productname} </MKTypography>
                                {(customizableIOTDBdetails.overview) && (
                                    <MKTypography variant="h6" textAlign="justify" m={"auto"} color="dark"
                                        fontWeight="regular" lineHeight={2}>
                                        {customizableIOTDBdetails.overview}
                                    </MKTypography>
                                )
                                }
                                {(customizableIOTDBdetails.overviewlist) && (
                                    <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                        <ul>
                                            {customizableIOTDBdetails.overviewlist.map((column) => {
                                                return (
                                                    <>
                                                        <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                            fontWeight="regular" lineHeight={2} key={column}>
                                                            {column}
                                                        </MKTypography></li></>
                                                );
                                            })}
                                        </ul>
                                    </Grid>
                                )}
                            </Grid>
                            {(customizableIOTDBdetails.overview1) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview1}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview1list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview1list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview2) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview2}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview2list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview2list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2}>
                                <OnMobile>
                                    <MKBox component="img" src={dbloginpageimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dbloginpageimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dbloginpageimg} alt="About US" maxWidth="75rem" height="35rem" borderRadius="xl" />
                            </Grid> */}
                            {(customizableIOTDBdetails.overview3) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview3}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview3list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview3list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2} >
                                <OnMobile>
                                    <MKBox component="img" src={dbhomepageimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dbhomepageimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dbhomepageimg} alt="About US" maxWidth="75rem" height="35rem" borderRadius="xl" />
                            </Grid> */}
                            {(customizableIOTDBdetails.overview4) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview4}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview4list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview4list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2}>
                                <OnMobile>
                                    <MKBox component="img" src={dbuserpageimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dbuserpageimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dbuserpageimg} alt="About US" maxWidth="75rem" height="35rem" borderRadius="xl" />
                            </Grid> */}
                            {(customizableIOTDBdetails.overview5) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview5}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview5list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview5list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2}>
                                <OnMobile>
                                    <MKBox component="img" src={dborgpageimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dborgpageimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dborgpageimg} alt="About US" maxWidth="75rem" height="35rem" borderRadius="xl" />
                            </Grid> */}
                            {(customizableIOTDBdetails.overview6) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview6}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview6list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview6list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2} >
                                <OnMobile>
                                    <MKBox component="img" src={dbdevicespageimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dbdevicespageimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dbdevicespageimg} alt="About US" maxWidth="75rem" height="35rem" borderRadius="xl" />
                            </Grid> */}
                            {(customizableIOTDBdetails.overview7) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview7}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview7list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview7list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2}>
                                <OnMobile>
                                    <MKBox component="img" src={dbplatinfopageimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dbplatinfopageimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dbplatinfopageimg} alt="About US" maxWidth="75rem" height="35rem" borderRadius="xl" />
                            </Grid> */}
                            {(customizableIOTDBdetails.overview8) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" my={2} lineHeight={2}>
                                    <MKTypography variant="h6" textAlign="justify" color="dark"
                                        fontWeight="bold" lineHeight={2} >
                                        {customizableIOTDBdetails.overview8}</MKTypography>
                                </Grid>
                            )}
                            {(customizableIOTDBdetails.overview8list) && (
                                <Grid item xs={12} md={12} lg={12} justifyContent="justify" ml={5} lineHeight={2}>
                                    <ul>
                                        {customizableIOTDBdetails.overview8list.map((column) => {
                                            return (
                                                <>
                                                    <li><MKTypography variant="h6" textAlign="justify" m="auto" color="dark"
                                                        fontWeight="regular" lineHeight={2} key={column}>
                                                        {column}
                                                    </MKTypography></li></>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify" mt={2}>
                                <OnMobile>
                                    <MKBox component="img" src={dbdevicepageimg} alt="About US" maxWidth="22rem" height="auto" borderRadius="xl" />
                                </OnMobile>
                                <OnDesktop>
                                    <MKBox component="img" src={dbdevicepageimg} alt="About US" maxWidth="75rem" height="auto" borderRadius="xl" />
                                </OnDesktop>
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={12} justifyContent="justify" >
                                <MKBox component="img" src={dbdevicepageimg} alt="About US" maxWidth="75rem" height="35rem" borderRadius="xl" />
                            </Grid> */}
                        </Grid>
                    </Container>
                )}

            </MKBox>
            < MKBox pt={5} px={0} mt={5} >
                <DefaultFooter content={footerRoutes} />
            </MKBox >
        </div>
    );
}

export default Product_Page;
