import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";


const case_studies = window.case_studies_details;
const case_da = case_studies.case_studies_video;
import { layoutGenerator } from 'react-break';

import gsap from 'gsap';
import { useEffect, useRef } from 'react';
const layout = layoutGenerator({
    mobile: 0,
    // phablet: 550,
    // tablet: 768,
    desktop: 992,
});
const OnMobile = layout.is('mobile');
const OnDesktop = layout.is('desktop');
function CaseStudiesVideo() {
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            // Split text into characters for individual animation
            const chars = textRef.current.innerText.split('');
            textRef.current.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

            // Animate each character with GSAP
            gsap.fromTo(textRef.current.children,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, stagger: 0.05, ease: 'back.out(1.7)' }
            );
        }
    }, []);
    return (
        <MKBox component="section" mt={10} py={2} id='casestudiesvideo'>
            <Container>
                <Grid container item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={4} lg={4} justifyContent="center" >
                        <MKTypography
                            variant="Overline"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="light"
                        // sx={({ breakpoints, typography: { size } }) => ({
                        //     [breakpoints.down("md")]: {
                        //         fontSize: size["xl"],
                        //     },
                        // })}
                        >
                            Eoxys
                        </MKTypography>
                        <MKTypography
                            variant="h2"
                            color="dark"
                            textTransform="capitalize"
                            fontWeight="bold"
                            ref={textRef}
                        >
                            Our Case Studies
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} justifyContent="center" >
                        <MKTypography
                            variant="body1"
                            color="dark"
                            textAlign="justify"
                            fontWeight="regular"
                        >
                            Empowering Customers Progress, Together Building Stronger Digital transformation Tales of Case studies
                        </MKTypography>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container item xs={12} lg={12} pt={2} sx={{ mx: "auto" }}>
                    {
                        (case_da.map((caseinfo) => {
                            return <Grid item xs={12} md={6} lg={4} px={2} key={caseinfo.id}>

                                <OnMobile>
                                    <iframe width="300" height="280"
                                        src={caseinfo.link}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnMobile>
                                <OnDesktop>
                                    <iframe width="350" height="280"
                                        src={caseinfo.link}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </OnDesktop>
                            </Grid>
                        }))
                    }
                    <Link to="/successstories" key={1} sx={{
                        display: "flex",
                        justifyContent: "end"
                    }} >

                        <MKTypography
                            variant="body1"
                            color="info"
                            textAlign="end"
                            fontWeight="regular"
                        >
                            Know more...


                        </MKTypography>

                    </Link>
                </Grid>
            </Container>
        </MKBox >
    );
}

export default CaseStudiesVideo;
