// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import eoxyslogo from "assets/images/logos/e_logo.png";
import meritechlogo from "assets/images/logos/logo_mt.png";
// import Product from "pages/Website/sections/Product";
// import Service from "pages/Website/sections/Service";
// import About from "pages/Website/sections/About";
// import News from "pages/Website/sections/News";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Eoxys Edge AIML Product Engineering",
    image: eoxyslogo,
    href: "/website",
    pcname: "Meritech Co",
    pcimage: meritechlogo,
    pcbranchjapan: "Meritech Co. Ltd, Japan",
    pcbranchusa: "Meritech Solutions Inc, USA",
    pcbranchindia: "Meritech Software Solutions Pvt Ltd, India.",
    pcroute: "https://meritechsolutions.com/",
    title: "Keep in touch, and stay connected.",
    contactno: {
      icon: <LocalPhoneIcon />,
      value: "+91-80-23652266",
    },
    contactemail: {
      icon: <EmailIcon />,
      value: "info@eoxys.com",
    },
    contactaddress: {
      icon: <LocationOnIcon />,
      value: "22, 7th Cross,KHB Main Road, Kaval Bairasandra Extn,RT Nagar,Bengaluru, Karnataka, 560032, India.",
    },
  },
  navbar_data: [
    {
      name: "",
      items: [
        { name: "Products", route: "/website#product" },
        { name: "Services", route: "/website#service" },
        { name: "Company", route: "/website#aboutus" },
        { name: "Success Stories", route: "/successstories" },
        {
          name: "Insights",
          route: "/website#news"
        },
        { name: "Store", route: "/store" },
        { name: "Contact us", route: "/website#contactus", },
      ],
    },
  ],
  socials: [
    {
      icon: <FacebookIcon />,
      color: "facebookColor",
      link: "https://www.facebook.com/Eoxys-System-285407735225252/",
    },
    {
      icon: <svg xmlns="http://www.w3.org/2000/svg" height="1em" style={{fill:'#ffffff'}} viewBox="0 0 448 512" >
        <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 
        64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 
        123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4
         142.9H125.1L296.9 367.6h26.3z" /></svg>
      ,
      color: "xTwitterColor",
      link: "https://twitter.com/EoxysSystems",
    },
    {
      icon: <LinkedInIcon />,
      color: "linkedINColor",
      link: "https://www.linkedin.com/company/eoxys-systems-india-pvt--ltd--",
    },
    {
      icon: <YouTubeIcon />,
      color: "youTubeColor",
      link: "https://www.youtube.com/@eoxyssystems5598",
    },
  ],
  menus: [
    {
      name: "Products",
      items: [
        { name: "Edge AIML SOMs", route: "/website#edgeaimlsom" },
        // { name: "Edge AIML RTU Modules", route: "/website#edgeaimlrtu" },
        { name: "Edge AIML EVK Kits", route: "/website#edgeaimlevkkit" },
        { name: "SW  Development Support", route: "/website#edgeaimlswdev" },
        { name: "ML Models", route: "/website#edgeaimlmodel" },
      ],
    },
    {
      name: "Services",
      items: [
        { name: "Product Engineering", route: "/website#service" },
        { name: "Cloud Software Engineering", route: "/website#service" },
        // { name: "AIML Model Engineering", route: "/website#service" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      {/* Copyright © 2010 - 2023 Eoxys Systems India Pvt Ltd. */}
      Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.eoxys.com/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Eoxys Systems India Pvt Ltd
      </MKTypography>
      .
    </MKTypography>
  ),
};
