import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, navbar_data, socials, menus, copyright } = content;
  return (
    <MKBox component="footer" bgColor="headerNavColor" >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={15} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} textAlign="center" justifyContent="center" maxWidth="8rem" mb={2} />
              </Link>
              <MKBox>
                <br />
                <MKTypography
                  variant="h5" color="white">A Meritech Group Company</MKTypography>
                <MKTypography
                  component="a"
                  target="_blank"
                  rel="noreferrer" color="white"
                  href={brand.pcroute}>
                  <MKBox component="img" src={brand.pcimage} alt={brand.pcname} textAlign="center" justifyContent="center" maxWidth="10rem" my={1} />
                </MKTypography>
                <br />
                <MKTypography
                  component="a"
                  target="_blank"
                  href={brand.pcroute}
                  rel="noreferrer"
                  variant="button"
                  fontWeight="medium" color="white"
                  textTransform="capitalize">{brand.pcbranchjapan} <br /> {brand.pcbranchusa} <br /> {brand.pcbranchindia}</MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
          {navbar_data.map(({ name: title, items }) => (
            <Grid key={title} item xs={12} md={2} sx={{ mb: 3 }}>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    <br />
                    <>
                      {(name != "Store") && (
                        <>{
                          href ? (
                            <MKTypography
                              component="a"
                              href={href}
                              // target="_blank"
                              rel="noreferrer"
                              variant="button"
                              color="white"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {name}
                            </MKTypography>

                          ) : (
                            <MKTypography
                              component={Link}
                              to={route}
                              variant="button"
                              fontWeight="regular"
                              color="white"
                              textTransform="capitalize"
                            >
                              {name}
                            </MKTypography>
                          )}</>)}
                      {(name == "Store") && (
                        <>{
                          href ? (
                            <MKTypography
                              component="a"
                              href={href}
                              target="_blank"
                              rel="noreferrer"
                              variant="button"
                              color="white"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              {name}
                            </MKTypography>

                          ) : (
                            <MKTypography
                              component={Link}
                              to={route}
                              variant="button"
                              fontWeight="regular"
                              color="white"
                              textTransform="capitalize"
                            >
                              {name}
                            </MKTypography>
                          )}</>)}

                    </>
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={12} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button && h6"
                fontWeight="bold"
                color="white"
                textTransform="capitalize"
                my={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0}
                    my={3} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        // target="_blank"
                        rel="noreferrer"
                        variant="button"
                        color="white"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        color="white"
                        fontWeight="regular"
                        textTransform="capitalize"
                        my={2}
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
                <br />
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKTypography variant="h6"
              color="white">{brand.title}</MKTypography>
            <MKBox mt={3}>
              {socials.map(({ icon, link, color }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  fontWeight="regular"
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color={color}
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
            <MKBox mt={1}>
              <MKTypography
                component="a"
                rel="noreferrer"
                variant="h6"
                fontWeight="regular"
                color="white"
                opacity={1}
              >
                {brand.contactno.icon} {brand.contactno.value}
              </MKTypography>
            </MKBox>
            <MKBox mt={1}>
              <MKTypography
                component="a"
                rel="noreferrer"
                variant="h6"
                fontWeight="regular"
                color="white"
                opacity={1}
              >
                {brand.contactemail.icon} {brand.contactemail.value}
              </MKTypography>
            </MKBox>
            <MKBox mt={1}>
              <MKTypography
                component="a"
                rel="noreferrer"
                fontWeight="regular"
                variant="h6"
                color="white"
                opacity={1}
              >
                {brand.contactaddress.icon} {brand.contactaddress.value}
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", my: 1 }} >
            <MKTypography variant="h6"
              color="white" px={3}>{copyright}</MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox >
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};
export default DefaultFooter;
