import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useLocation } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "components/Footer";
import DefaultNavbar from "components/Header";
import footerRoutes from "footer.routes";
import routes from "routes";

import wmd_device from "assets/images/success_stories/WM_sensor.jpg";
import Wmd_sensor from "assets/images/success_stories/wmd_screen.jpg";
import pestsense from "assets/images/success_stories/pestsense-removebg-preview.png";
import iotcamera from "assets/images/success_stories/Wifi_iot_camara.png";
import WifiACDdevice from "assets/images/success_stories/Audio_classification_decive-removebg-preview.png";
import NBIOTACDdevice from "assets/images/success_stories/NBIoT_ACD.png";
import Ble_Beacon from "assets/images/success_stories/Ble_Beacon-removebg-preview.png";
import Roadquality_device from "assets/images/success_stories/Roadquality_device-removebg-preview.png";
import iot_dashboard from "assets/images/success_stories/iot_dashboard.png";
import iot_dashboard1 from "assets/images/success_stories/iot_dashboard1.png";
import ECG_Flowgraph from "assets/images/success_stories/ECG_Flowgraph.png";
import mobilepageEEG from "assets/images/success_stories/mobilepageEEG.png";
import sensor_dashboard from "assets/images/success_stories/sensor_dashboard.png";
import sensor_graph01 from "assets/images/success_stories/sensor_graph01.png";
import sensor_graph1 from "assets/images/success_stories/sensor_graph1.png";
import sensor_recordings from "assets/images/success_stories/sensor_recordings.png";
import homepageEEG from "assets/images/success_stories/homepageEEG.png";
import tuneapp from "assets/images/success_stories/middleware_sensor.png";
import dashboard_fuel from "assets/images/success_stories/dashboard_fuel.png";
import config_fuel from "assets/images/success_stories/config_fuel.png";
import login_bumper from "assets/images/success_stories/login.png";
import signup_bumper from "assets/images/success_stories/signup.png";
import blog_bumper from "assets/images/success_stories/blog.png";
import video_bumper from "assets/images/success_stories/video copy.png";
import sso from "assets/images/success_stories/SSO.png";
import vibrationsensordevice from "assets/images/success_stories/vbr_sensor.png";
import sensorwatch from "assets/images/success_stories/sensorwatch.png";
import smartlock from "assets/images/success_stories/smartlock.png";
import dashboard from "assets/images/success_stories/dashboard.png";
import mediwatch from "assets/images/success_stories/mediwatch.png";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { layoutGenerator } from 'react-break';
import ScrollToTop from "react-scroll-to-top";

//Tabs
import * as React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Icon from "@mui/material/Icon";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const layout = layoutGenerator({
    mobile: 0,
    // phablet: 550,
    // tablet: 768,
    desktop: 992,
});

const OnMobile = layout.is('mobile');
const OnDesktop = layout.is('desktop');
function Success_Stories_Page() {
    var location = useLocation();
    var pathname = location.pathname.split("/");
    var productpage = pathname[pathname.length - 1];
    console.log("location ", location.pathname, " pathname ", pathname.length, productpage);
    // IoT Product Engineering
    const wmddetails = window.wmd;
    const psddetails = window.psd;
    const blegwdetails = window.blegw;
    const wifiiotcameradetails = window.wifiiotcamera;
    const wifiacddetails = window.wifiacd;
    const nbiotacddetails = window.nbiotacd;
    const rqddetails = window.rqd;
    // Cloud Server Engineering
    // const estoredetails = window.ecgiotDB;
    const ecgiotDBdetails = window.ecgiotDB;
    const acdiotDBdetails = window.acdiotDB;
    const sensorserverdetails = window.sensorserver;
    const tuneappdetails = window.tuneapp;
    const fmsdetails = window.fms;
    const bumperglassdetails = window.bumperglass;
    // Case Studies
    const patientcaredetails = window.patientcare;
    const smartlockdetails = window.smartlock;
    const mediwatchdetails = window.mediwatch;
    const vibrationsensordevicedetails = window.vibrationsensordevice;
    const fsmartfactoryautomationdetails = window.smartfactoryautomation;
    const ssoappdetails = window.ssoapp;



    const images = [
        {
            id: 1,
            imgPath: mobilepageEEG,
        },
        {
            id: 2,
            imgPath: homepageEEG,
        },
        {
            id: 3,
            imgPath: ECG_Flowgraph,
        },
    ];
    const images1 = [
        {
            id: 1,
            imgPath: sensor_dashboard,
        },
        {
            id: 2,
            imgPath: sensor_graph01,
        },
        {
            id: 3,
            imgPath: sensor_graph1,
        },
        {
            id: 4,
            imgPath: sensor_recordings,
        },
    ];
    const images2 = [
        {
            id: 1,
            imgPath: iot_dashboard,
        },
        {
            id: 2,
            imgPath: iot_dashboard1,
        },

    ];

    const images3 = [
        {
            id: 1,
            imgPath: dashboard_fuel,
        },
        {
            id: 2,
            imgPath: config_fuel,
        },

    ];

    const images4 = [
        {
            id: 1,
            imgPath: login_bumper,
        },
        {
            id: 2,
            imgPath: signup_bumper,
        },
        {
            id: 3,
            imgPath: blog_bumper,
        },
        {
            id: 4,
            imgPath: video_bumper,
        },

    ];
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div>

            <ScrollToTop smooth color="#000000" />

            <MKBox pt={5} px={0} mt={-5}>
                <DefaultNavbar routes={routes} sticky />
            </MKBox>

            <MKBox component="section" my={15} py={2} sx={{ overflow: "hidden" }}
            >
                <Grid item xs={12} md={12} lg={12} p={5} >
                    <MKTypography
                        variant="h4"
                        fontWeight="bold"
                        textAlign="center"
                        color="dark"
                        textTransform="capitalize" id="iotpe"
                        my={2}
                        m="auto"
                    >
                        {" "}
                        IOT Product Engineering{" "}
                    </MKTypography><hr color="#000000" style={{ margin: 5 }} />
                </Grid>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            {/* <Grid item xs={6} md={6} lg={6} justifyContent="justify"> */}

                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid item xs={12} md={6} lg={6} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={wmd_device}
                                            alt="About us Images"
                                            width="15rem"
                                            height="15rem"

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} sm={6} justifyContent="center"  m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={Wmd_sensor}
                                            alt="About us Images"
                                            width="15rem"
                                            height="15rem"
                                        // mt="50px"

                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid item xs={12} md={6} lg={3} sm={6} justifyContent="justify" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={wmd_device}
                                            alt="About us Images"
                                            maxWidth="17rem"
                                            height="17rem"

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} sm={6} justifyContent="justify" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={Wmd_sensor}
                                            alt="About us Images"
                                            maxWidth="17rem"
                                            height="17rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>


                            {/* </Grid> */}
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="iotpewmd"
                                >
                                    {" "}
                                    {wmddetails.productname}{" "}
                                </MKTypography>
                                {wmddetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wmddetails.overview}
                                    </MKTypography>
                                )}
                                {wmddetails.overviewlist &&
                                    wmddetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {wmddetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wmddetails.overview1title} :
                                    </MKTypography>
                                )}
                                {wmddetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wmddetails.overview1}
                                    </MKTypography>
                                )}
                                {wmddetails.overview1list &&
                                    wmddetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {wmddetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wmddetails.overview2title} :
                                    </MKTypography>
                                )}
                                {wmddetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wmddetails.overview2}
                                    </MKTypography>
                                )}
                                {wmddetails.overview2list &&
                                    wmddetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>

                <Container >
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>

                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={pestsense}
                                            alt="About us Images"
                                            maxWidth="30rem"
                                            height="20rem"

                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={pestsense}
                                            alt="About us Images"
                                            maxWidth="27rem"
                                            height="30rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="iotpepsd"
                                >
                                    {" "}
                                    {psddetails.productname}{" "}
                                </MKTypography>
                                {psddetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {psddetails.overview}
                                    </MKTypography>
                                )}
                                {psddetails.overviewlist &&
                                    psddetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {psddetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {psddetails.overview1title} :
                                    </MKTypography>
                                )}
                                {psddetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {psddetails.overview1}
                                    </MKTypography>
                                )}
                                {psddetails.overview1list &&
                                    psddetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {psddetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {psddetails.overview2title} :
                                    </MKTypography>
                                )}
                                {psddetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {psddetails.overview2}
                                    </MKTypography>
                                )}
                                {psddetails.overview2list &&
                                    psddetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {psddetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {psddetails.overview3title} :
                                    </MKTypography>
                                )}
                                {psddetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {psddetails.overview3}
                                    </MKTypography>
                                )}
                                {psddetails.overview3list &&
                                    psddetails.overview3list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {psddetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {psddetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container >
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} sm={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={Ble_Beacon}
                                            alt="About us Images"
                                            maxWidth="20rem"
                                            height="15rem"
                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={Ble_Beacon}
                                            alt="About us Images"
                                            maxWidth="32rem"
                                            height="25rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="iotpeblegw"
                                >
                                    {" "}
                                    {blegwdetails.productname}{" "}
                                </MKTypography>
                                {blegwdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overview}
                                    </MKTypography>
                                )}
                                {blegwdetails.overviewlist &&
                                    blegwdetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {blegwdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {blegwdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overview1}
                                    </MKTypography>
                                )}
                                {blegwdetails.overview1list &&
                                    blegwdetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {blegwdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {blegwdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overview2}
                                    </MKTypography>
                                )}
                                {blegwdetails.overview2list &&
                                    blegwdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {blegwdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overview3title} :
                                    </MKTypography>
                                )}
                                {blegwdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overview3}
                                    </MKTypography>
                                )}
                                {blegwdetails.overview3list &&
                                    blegwdetails.overview3list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {blegwdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {blegwdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container >
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            {/* <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKBox
                                    component="img"
                                    justifyContent="centre"
                                    src={iotcamera}
                                    alt="About us Images"
                                    maxWidth="22rem"
                                    height="12rem"
                                    
                                // borderRadius="xl" shadow="xl" coloredShadow="success"
                                />
                            </Grid> */}
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={iotcamera}
                                            alt="About us Images"
                                            maxWidth="18rem"
                                            height="15rem"
                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={iotcamera}
                                            alt="About us Images"
                                            maxWidth="31rem"
                                            height="23rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="iotpeiotcamera"
                                >
                                    {" "}
                                    {wifiiotcameradetails.productname}{" "}
                                </MKTypography>
                                {wifiiotcameradetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overview}
                                    </MKTypography>
                                )}
                                {wifiiotcameradetails.overviewlist &&
                                    wifiiotcameradetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {wifiiotcameradetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overview1title} :
                                    </MKTypography>
                                )}
                                {wifiiotcameradetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overview1}
                                    </MKTypography>
                                )}
                                {wifiiotcameradetails.overview1list &&
                                    wifiiotcameradetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {wifiiotcameradetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overview2title} :
                                    </MKTypography>
                                )}
                                {wifiiotcameradetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overview2}
                                    </MKTypography>
                                )}
                                {wifiiotcameradetails.overview2list &&
                                    wifiiotcameradetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {wifiiotcameradetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        ccolor="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overview3title} :
                                    </MKTypography>
                                )}
                                {wifiiotcameradetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overview3}
                                    </MKTypography>
                                )}
                                {wifiiotcameradetails.overview3list &&
                                    wifiiotcameradetails.overview3list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {wifiiotcameradetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiiotcameradetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid></Card>
                </Container>

                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            {/* <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKBox
                                    component="img"
                                    justifyContent="centre"
                                    src={NBIOTACDdevice}
                                    alt="About us Images"
                                    maxWidth="25rem"
                                    height="15rem"
                                    m="20px"
                                // borderRadius="xl" shadow="xl" coloredShadow="success"
                                />
                            </Grid> */}
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={NBIOTACDdevice}
                                            alt="About us Images"
                                            maxWidth="25rem"
                                            height="15rem"
                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={NBIOTACDdevice}
                                            alt="About us Images"
                                            maxWidth="40rem"
                                            height="28rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="iotpenacd"
                                >
                                    {" "}
                                    {nbiotacddetails.productname}{" "}
                                </MKTypography>
                                {nbiotacddetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overview}
                                    </MKTypography>
                                )}
                                {nbiotacddetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {nbiotacddetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {nbiotacddetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overview1title} :
                                    </MKTypography>
                                )}
                                {nbiotacddetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overview1}
                                    </MKTypography>
                                )}
                                {nbiotacddetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {nbiotacddetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {nbiotacddetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overview2title} :
                                    </MKTypography>
                                )}
                                {nbiotacddetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overview2}
                                    </MKTypography>
                                )}
                                {nbiotacddetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {nbiotacddetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {nbiotacddetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overview3title} :
                                    </MKTypography>
                                )}
                                {nbiotacddetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overview3}
                                    </MKTypography>
                                )}
                                {nbiotacddetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {nbiotacddetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {nbiotacddetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {nbiotacddetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>

                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            {/* <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKBox
                                    component="img"
                                    justifyContent="centre"
                                    src={WifiACDdevice}
                                    alt="About us Images"
                                    maxWidth="25rem"
                                    height="15rem"
                                    m="20px"
                                // borderRadius="xl" shadow="xl" coloredShadow="success"
                                />
                            </Grid> */}
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={WifiACDdevice}
                                            alt="About us Images"
                                            maxWidth="25rem"
                                            height="15rem"
                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={WifiACDdevice}
                                            alt="About us Images"
                                            maxWidth="35rem"
                                            height="25rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="iotpewacd"
                                >
                                    {" "}
                                    {wifiacddetails.productname}{" "}
                                </MKTypography>
                                {wifiacddetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overview}
                                    </MKTypography>
                                )}
                                {wifiacddetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {wifiacddetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {wifiacddetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overview1title} :
                                    </MKTypography>
                                )}
                                {wifiacddetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overview1}
                                    </MKTypography>
                                )}
                                {wifiacddetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {wifiacddetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {wifiacddetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overview2title} :
                                    </MKTypography>
                                )}
                                {wifiacddetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overview2}
                                    </MKTypography>
                                )}
                                {wifiacddetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {wifiacddetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {wifiacddetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overview3title} :
                                    </MKTypography>
                                )}
                                {wifiacddetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overview3}
                                    </MKTypography>
                                )}
                                {wifiacddetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {wifiacddetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {wifiacddetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {wifiacddetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>

                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={Roadquality_device}
                                            alt="About us Images"
                                            maxWidth="20rem"
                                            height="15rem"
                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={Roadquality_device}
                                            alt="About us Images"
                                            maxWidth="31rem"
                                            height="23rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="iotperqd"
                                >
                                    {" "}
                                    {rqddetails.productname}{" "}
                                </MKTypography>
                                {rqddetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overview}
                                    </MKTypography>
                                )}
                                {rqddetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {rqddetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {rqddetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overview1title} :
                                    </MKTypography>
                                )}
                                {rqddetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overview1}
                                    </MKTypography>
                                )}
                                {rqddetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {rqddetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {rqddetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overview2title} :
                                    </MKTypography>
                                )}
                                {rqddetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overview2}
                                    </MKTypography>
                                )}
                                {rqddetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {rqddetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {rqddetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overview3title}
                                    </MKTypography>
                                )}
                                {rqddetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overview3}
                                    </MKTypography>
                                )}
                                {rqddetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {rqddetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {rqddetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {rqddetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>

                <Grid item xs={12} md={12} lg={12} p={5} >
                    <MKTypography
                        variant="h4"
                        fontWeight="bold"
                        textAlign="center"
                        color="dark"
                        textTransform="upperCase" id="cloud"
                        my={2}
                        m="auto"
                    >
                        {" "}
                        Cloud Software Engineering{" "}
                    </MKTypography><hr color="#000000" style={{ margin: 5 }} />
                </Grid>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="cloudecgiot"
                                >
                                    {" "}
                                    {ecgiotDBdetails.productname}{" "}
                                </MKTypography>
                                {ecgiotDBdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overview}
                                    </MKTypography>
                                )}
                                {ecgiotDBdetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {ecgiotDBdetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )
                                }
                                {ecgiotDBdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {ecgiotDBdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overview1}
                                    </MKTypography>
                                )}
                                {ecgiotDBdetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {ecgiotDBdetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {ecgiotDBdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {ecgiotDBdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overview2}
                                    </MKTypography>
                                )}
                                {ecgiotDBdetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {ecgiotDBdetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {ecgiotDBdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overview3title} :
                                    </MKTypography>
                                )}
                                {ecgiotDBdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overview3}
                                    </MKTypography>
                                )}
                                {ecgiotDBdetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {ecgiotDBdetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {ecgiotDBdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ecgiotDBdetails.overviewvideo}
                                    </MKTypography>
                                )}

                            </Grid>
                            <OnMobile>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    height="13rem" width="17rem"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnMobile>
                            <OnDesktop>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    minHeight="75vh"
                                                    width="100%"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnDesktop>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="cloudacdiot"
                                >
                                    {" "}
                                    {acdiotDBdetails.productname}{" "}
                                </MKTypography>
                                {acdiotDBdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overview}
                                    </MKTypography>
                                )}
                                {acdiotDBdetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {acdiotDBdetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {acdiotDBdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {acdiotDBdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overview1}
                                    </MKTypography>
                                )}
                                {acdiotDBdetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {acdiotDBdetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {acdiotDBdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {acdiotDBdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overview2}
                                    </MKTypography>
                                )}
                                {acdiotDBdetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {acdiotDBdetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {acdiotDBdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overview3title} :
                                    </MKTypography>
                                )}
                                {acdiotDBdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overview3}
                                    </MKTypography>
                                )}
                                {acdiotDBdetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {acdiotDBdetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {acdiotDBdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {acdiotDBdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                            <OnMobile>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images2.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    height="11rem" width="17.5rem"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnMobile>
                            <OnDesktop>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images2.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    minHeight="75vh"
                                                    width="100%"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnDesktop>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="cloudsensorserver"
                                >
                                    {" "}
                                    {sensorserverdetails.productname}{" "}
                                </MKTypography>
                                {sensorserverdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overview}
                                    </MKTypography>
                                )}
                                {sensorserverdetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {sensorserverdetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {sensorserverdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {sensorserverdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overview1}
                                    </MKTypography>
                                )}
                                {sensorserverdetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {sensorserverdetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {sensorserverdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {sensorserverdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overview2}
                                    </MKTypography>
                                )}
                                {sensorserverdetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {sensorserverdetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {sensorserverdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overview3title} :
                                    </MKTypography>
                                )}
                                {sensorserverdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overview3}
                                    </MKTypography>
                                )}
                                {sensorserverdetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {sensorserverdetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {sensorserverdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {sensorserverdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                            <OnMobile>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images1.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    height="11rem" width="17.5rem"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnMobile>
                            <OnDesktop>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images1.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    minHeight="75vh"
                                                    width="100%"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnDesktop>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="cloudtuneapp"
                                >
                                    {" "}
                                    {tuneappdetails.productname}{" "}
                                </MKTypography>
                                {tuneappdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overview}
                                    </MKTypography>
                                )}
                                {tuneappdetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {tuneappdetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {tuneappdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {tuneappdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overview1}
                                    </MKTypography>
                                )}
                                {tuneappdetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {tuneappdetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {tuneappdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {tuneappdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overview2}
                                    </MKTypography>
                                )}
                                {tuneappdetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {tuneappdetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {tuneappdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overview3title} :
                                    </MKTypography>
                                )}
                                {tuneappdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overview3}
                                    </MKTypography>
                                )}
                                {tuneappdetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {tuneappdetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {tuneappdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {tuneappdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={tuneapp}
                                            alt="About us Images"
                                            maxWidth="20rem"
                                            height="15rem"
                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            m="auto"
                                            textAlign="center"
                                            justifyContent="center"
                                            src={tuneapp}
                                            alt="About us Images"
                                            maxWidth="95rem"
                                            height="25rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="cloudfms"
                                >
                                    {" "}
                                    {fmsdetails.productname}{" "}
                                </MKTypography>
                                {fmsdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overview}
                                    </MKTypography>
                                )}
                                {fmsdetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {fmsdetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {fmsdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {fmsdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overview1}
                                    </MKTypography>
                                )}
                                {fmsdetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {fmsdetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {fmsdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        ccolor="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {fmsdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overview2}
                                    </MKTypography>
                                )}
                                {fmsdetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {fmsdetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {fmsdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overview3title} :
                                    </MKTypography>
                                )}
                                {fmsdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overview3}
                                    </MKTypography>
                                )}
                                {fmsdetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {fmsdetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {fmsdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fmsdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                            <OnMobile>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images3.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    height="11rem" width="17.5rem"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnMobile>
                            <OnDesktop>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images3.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    minHeight="75vh"
                                                    width="100%"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnDesktop>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="cloudbumperglass"
                                >
                                    {" "}
                                    {bumperglassdetails.productname}{" "}
                                </MKTypography>
                                {bumperglassdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overview}
                                    </MKTypography>
                                )}
                                {bumperglassdetails.overviewlist &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {bumperglassdetails.overviewlist.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {bumperglassdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {bumperglassdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overview1}
                                    </MKTypography>
                                )}
                                {bumperglassdetails.overview1list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {bumperglassdetails.overview1list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {bumperglassdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {bumperglassdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overview2}
                                    </MKTypography>
                                )}
                                {bumperglassdetails.overview2list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {bumperglassdetails.overview2list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {bumperglassdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overview3title} :
                                    </MKTypography>
                                )}
                                {bumperglassdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overview3}
                                    </MKTypography>
                                )}
                                {bumperglassdetails.overview3list &&
                                    (
                                        <Grid item xs={12} md={12} lg={12} justifyContent="justify" m={3} lineHeight={2}>
                                            <ul>
                                                {bumperglassdetails.overview3list.map((column) => {
                                                    return (
                                                        <>
                                                            <li><MKTypography variant="h6"
                                                                textAlign="justify"
                                                                m="auto"
                                                                color="dark"
                                                                fontWeight="regular"
                                                                lineHeight={2}
                                                                key={column}>
                                                                {column}
                                                            </MKTypography>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul></Grid>
                                    )}
                                {bumperglassdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {bumperglassdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                            <OnMobile>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images4.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    height="11rem" width="17.5rem"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnMobile>
                            <OnDesktop>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {images4.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <MKBox component="img" justifyContent="centre" src={step.imgPath} alt="About us Images"
                                                    minHeight="75vh"
                                                    width="100%"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </OnDesktop>
                        </Grid>
                    </Card>
                </Container>

                <Grid item xs={12} md={12} lg={12} p={5} >
                    <MKTypography
                        variant="h4"
                        fontWeight="bold"
                        textAlign="center"
                        color="dark"
                        textTransform="capitalize" id="casestudies"
                        my={2}
                        m="auto"
                    >
                        {" "}
                        Case Studies {" "}
                    </MKTypography><hr color="#000000" style={{ margin: 5 }} />
                </Grid>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={sensorwatch}
                                            alt="About us Images"
                                            maxWidth="15rem"
                                            height="15rem"
                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={sensorwatch}
                                            alt="About us Images"
                                            maxWidth="30rem"
                                            height="30rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="casestudiesiotpc"
                                >
                                    {" "}
                                    {patientcaredetails.productname}{" "}
                                </MKTypography>
                                {patientcaredetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overview}
                                    </MKTypography>
                                )}
                                {patientcaredetails.overviewlist &&
                                    patientcaredetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {patientcaredetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overview1title} :
                                    </MKTypography>
                                )}
                                {patientcaredetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overview1}
                                    </MKTypography>
                                )}
                                {patientcaredetails.overview1list &&
                                    patientcaredetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {patientcaredetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overview2title} :
                                    </MKTypography>
                                )}
                                {patientcaredetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overview2}
                                    </MKTypography>
                                )}
                                {patientcaredetails.overview2list &&
                                    patientcaredetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {patientcaredetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overview3title} :
                                    </MKTypography>
                                )}
                                {patientcaredetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overview3}
                                    </MKTypography>
                                )}
                                {patientcaredetails.overview3list &&
                                    patientcaredetails.overview3list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {patientcaredetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {patientcaredetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={smartlock}
                                            alt="About us Images"
                                            maxWidth="25rem"
                                            height="15rem"

                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={smartlock}
                                            alt="About us Images"
                                            maxWidth="40rem"
                                            height="30rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="casestudiesiotsl"
                                >
                                    {" "}
                                    {smartlockdetails.productname}{" "}
                                </MKTypography>
                                {smartlockdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overview}
                                    </MKTypography>
                                )}
                                {smartlockdetails.overviewlist &&
                                    smartlockdetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {smartlockdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {smartlockdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overview1}
                                    </MKTypography>
                                )}
                                {smartlockdetails.overview1list &&
                                    smartlockdetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {smartlockdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {smartlockdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overview2}
                                    </MKTypography>
                                )}
                                {smartlockdetails.overview2list &&
                                    smartlockdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {smartlockdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {smartlockdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overview2}
                                    </MKTypography>
                                )}
                                {smartlockdetails.overview3list &&
                                    smartlockdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {smartlockdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {smartlockdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>

                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={mediwatch}
                                            alt="About us Images"
                                            maxWidth="20rem"
                                            height="15rem"

                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={mediwatch}
                                            alt="About us Images"
                                            maxWidth="35rem"
                                            height="30rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="casestudiesmwwsad"
                                >
                                    {" "}
                                    {mediwatchdetails.productname}{" "}
                                </MKTypography>
                                {mediwatchdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overview}
                                    </MKTypography>
                                )}
                                {mediwatchdetails.overviewlist &&
                                    mediwatchdetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {mediwatchdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {mediwatchdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overview1}
                                    </MKTypography>
                                )}
                                {mediwatchdetails.overview1list &&
                                    mediwatchdetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {mediwatchdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {mediwatchdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overview2}
                                    </MKTypography>
                                )}
                                {mediwatchdetails.overview2list &&
                                    mediwatchdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {mediwatchdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        ccolor="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {mediwatchdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overview2}
                                    </MKTypography>
                                )}
                                {mediwatchdetails.overview3list &&
                                    mediwatchdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {mediwatchdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {mediwatchdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            {/* <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKBox
                                    component="img"
                                    justifyContent="centre"
                                    src={vibrationsensordevice}
                                    alt="About us Images"
                                    maxWidth="25rem"
                                    height="15rem"
                                    m="20px"
                                // borderRadius="xl" shadow="xl" coloredShadow="success"
                                />
                            </Grid> */}
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            textAlign="center"
                                            src={vibrationsensordevice}
                                            alt="About us Images"
                                            maxWidth="35rem"
                                            height="30rem"

                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={vibrationsensordevice}
                                            alt="About us Images"
                                            maxWidth="40rem"
                                            height="30rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="casestudiesvsd"
                                >
                                    {" "}
                                    {vibrationsensordevicedetails.productname}{" "}
                                </MKTypography>
                                {vibrationsensordevicedetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overview}
                                    </MKTypography>
                                )}
                                {vibrationsensordevicedetails.overviewlist &&
                                    vibrationsensordevicedetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {vibrationsensordevicedetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overview1title} :
                                    </MKTypography>
                                )}
                                {vibrationsensordevicedetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overview1}
                                    </MKTypography>
                                )}
                                {vibrationsensordevicedetails.overview1list &&
                                    vibrationsensordevicedetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {vibrationsensordevicedetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overview2title} :
                                    </MKTypography>
                                )}
                                {vibrationsensordevicedetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overview2}
                                    </MKTypography>
                                )}
                                {vibrationsensordevicedetails.overview2list &&
                                    vibrationsensordevicedetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {vibrationsensordevicedetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overview2title} :
                                    </MKTypography>
                                )}
                                {vibrationsensordevicedetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overview2}
                                    </MKTypography>
                                )}
                                {vibrationsensordevicedetails.overview3list &&
                                    vibrationsensordevicedetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {vibrationsensordevicedetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {vibrationsensordevicedetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container>
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            textAlign="center"
                                            src={dashboard}
                                            alt="About us Images"
                                            maxWidth="17rem"
                                            height="10rem"

                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={dashboard}
                                            alt="About us Images"
                                            maxWidth="31rem"
                                            height="23rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="casestudiesiotdb"
                                >
                                    {" "}
                                    {fsmartfactoryautomationdetails.productname}{" "}
                                </MKTypography>
                                {fsmartfactoryautomationdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overview}
                                    </MKTypography>
                                )}
                                {fsmartfactoryautomationdetails.overviewlist &&
                                    fsmartfactoryautomationdetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {fsmartfactoryautomationdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {fsmartfactoryautomationdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overview1}
                                    </MKTypography>
                                )}
                                {fsmartfactoryautomationdetails.overview1list &&
                                    fsmartfactoryautomationdetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {fsmartfactoryautomationdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {fsmartfactoryautomationdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overview2}
                                    </MKTypography>
                                )}
                                {fsmartfactoryautomationdetails.overview2list &&
                                    fsmartfactoryautomationdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {fsmartfactoryautomationdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {fsmartfactoryautomationdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overview2}
                                    </MKTypography>
                                )}
                                {fsmartfactoryautomationdetails.overview3list &&
                                    fsmartfactoryautomationdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {fsmartfactoryautomationdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {fsmartfactoryautomationdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                <Container >
                    <Card sx={{ height: "100%", width: "100%", boxShadow: 3, mt: 4 }}>
                        <Grid container item xs={12} md={12} lg={12} justifyContent="justify" p={5}>
                            {/* <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKBox
                                    component="img"
                                    justifyContent="center"
                                    src={sso}
                                    alt="About us Images"
                                    maxWidth="25rem"
                                    height="15rem"
                                    m="20px"
                                // borderRadius="xl" shadow="xl" coloredShadow="success"
                                />
                            </Grid>  */}
                            <Grid item xs={12} md={12} lg={6} justifyContent="center">
                                <OnMobile>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            textAlign="center"
                                            src={sso}
                                            alt="About us Images"
                                            maxWidth="27rem"
                                            height="17rem"

                                        />
                                    </Grid>
                                </OnMobile>
                                <OnDesktop>
                                    <Grid xs={12} md={12} lg={12} sm={6} justifyContent="center" m={"auto"}>
                                        <MKBox
                                            component="img"
                                            justifyContent="center"
                                            src={sso}
                                            alt="About us Images"
                                            maxWidth="35rem"
                                            height="25rem"

                                        />
                                    </Grid>
                                </OnDesktop>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} justifyContent="justify">
                                <MKTypography
                                    variant="h4"
                                    fontWeight="bold"
                                    textAlign="center"
                                    color="dark"
                                    textTransform="capitalize"
                                    my={2}
                                    id="casestudiessso"
                                >
                                    {" "}
                                    {ssoappdetails.productname}{" "}
                                </MKTypography>
                                {ssoappdetails.overview && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overview}
                                    </MKTypography>
                                )}
                                {ssoappdetails.overviewlist &&
                                    ssoappdetails.overviewlist.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {ssoappdetails.overview1title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overview1title} :
                                    </MKTypography>
                                )}
                                {ssoappdetails.overview1 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overview1}
                                    </MKTypography>
                                )}
                                {ssoappdetails.overview1list &&
                                    ssoappdetails.overview1list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {ssoappdetails.overview2title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {ssoappdetails.overview2 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overview2}
                                    </MKTypography>
                                )}
                                {ssoappdetails.overview2list &&
                                    ssoappdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {ssoappdetails.overview3title && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="bold"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overview2title} :
                                    </MKTypography>
                                )}
                                {ssoappdetails.overview3 && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overview2}
                                    </MKTypography>
                                )}
                                {ssoappdetails.overview3list &&
                                    ssoappdetails.overview2list.map((column) => {
                                        return (
                                            <>
                                                <MKTypography
                                                    variant="h6"
                                                    textAlign="justify"
                                                    m="auto"
                                                    color="dark"
                                                    fontWeight="regular"
                                                    lineHeight={2}
                                                    key={column}
                                                >
                                                    <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
                                                        <FiberManualRecordIcon />
                                                    </Icon>{" "}
                                                    &nbsp;&nbsp; {column}
                                                </MKTypography>
                                            </>
                                        );
                                    })}
                                {ssoappdetails.overviewvideo && (
                                    <MKTypography
                                        variant="h6"
                                        textAlign="justify"
                                        m="auto"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight={2}
                                    >
                                        {ssoappdetails.overviewvideo}
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </MKBox>

            {/* footer */}
            <MKBox pt={5} px={0} mt={5}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </div>
    );
}
export default Success_Stories_Page;
